import React from 'react'
import { Tooltip, Badge, Icon, Box } from '@chakra-ui/react'
import { FaExclamationTriangle, FaUserFriends } from 'react-icons/fa'
import { IconType } from 'react-icons'
import { useUser } from './UserContext'

interface ProjectBadgeProps {
  icon?: IconType
  label?: string // Optional tooltip label
  fontSize?: string | number // Optional font size for dynamic scaling
  color?: string
  text?: string
  onClick?: React.MouseEventHandler<HTMLElement>
}

const ProjectBadge: React.FC<ProjectBadgeProps> = ({ icon, label, fontSize, color, text, onClick }) => {
  return (
    <Box m={1}>
      <Tooltip label={label} >
        <Badge
          colorScheme={color}
          borderRadius="full"
          px={2}
          py={1.5}
          cursor="pointer"
          variant="solid"
          fontSize={fontSize} // Apply fontSize only if passed
          onClick={onClick}
        >
           <Icon mr={text ? 1 : 0} as={icon} /> {/* Adjust icon size dynamically */}
          {text && text}
        </Badge>
      </Tooltip>
    </Box>
  )
}

export const SharedBadge: React.FC<{ fontSize?: string | number; label?: string }> = ({ fontSize, label }) => {
  return (
    <ProjectBadge
      icon={FaUserFriends}
      label={label || 'Shared Project'} // Default label
      fontSize={fontSize} // Optional font size
      color='blue'
    />
  )
}

export const UpgradeBadge: React.FC<{ fontSize?: string | number; label?: string, text?: string}> = ({ fontSize, label, text }) => {
  const { openUpgradeModal } = useUser()
  return (
    <ProjectBadge
      icon={FaExclamationTriangle}
      label={label || 'Upgrade to edit!'} // Default label
      fontSize={fontSize} // Optional font size
      color='red'
      text={text}
      onClick={(e) => {
        e.stopPropagation()
        openUpgradeModal()
      }}
    />
  )
}