/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Button,
  Text,
  useToast
} from '@chakra-ui/react'

import { Project } from '../../_helpers/types'
import { useProjectContext } from './ProjectContext'

interface CompleteProjectProps {
  isOpen: boolean
  onClose: () => void
  project: Project
}

const CompleteProject = (props: CompleteProjectProps) => {
  const toast = useToast()

  const {completeProject, activateProject} = useProjectContext();

  const handleCompleteProject = async () => {

    const successToastId = toast({
      title: 'Success!',
      description: 'Your project has been completed!',
      status: 'success',
      duration: 5000,
      isClosable: true
    })

    const completeProjectResult = await completeProject(props.project.id)
    if (!completeProjectResult) {
      if (successToastId) {
        toast.close(successToastId);
      }

      toast({
        title: 'Error',
        description: 'There was an error in completing your project. Please reload your page and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }

    props.onClose()
  }

  const handleActivateProject = async () => {
    const successToastId = toast({
      title: 'Success!',
      description: 'Your project has been activated!',
      status: 'success',
      duration: 5000,
      isClosable: true
    })
    const activateProjectResult = await activateProject(props.project.id)
    
    if (!activateProjectResult) {
      if (successToastId) {
        toast.close(successToastId);
      }

      toast({
        title: 'Error',
        description: 'There was an error in activating your project. Please reload your page and try again',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
    props.onClose()
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.project.isComplete ? "Activate Project" : "Complete Project"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4} p={4} bg='gray.100' borderRadius='lg' boxShadow='sm'>
            <Text mb={2} fontWeight='bold'>
              Summary
            </Text>
            <ul style={{ marginLeft: '3em' }}>
              <li>
                <Text>Project Name: {props.project.name}</Text>
              </li>
              <li>
                <Text>Number of Tracks: {props.project.numTracks}</Text>
              </li>
            </ul>
          </Box>
          <Button mb = {2}
            onClick={props.project.isComplete ? handleActivateProject : handleCompleteProject}
            colorScheme='green'
            fontSize='xl'
            variant='outline'
            _hover={{ color: 'white', bg: 'green' }}
            size='md'
          >
            Submit
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CompleteProject
