import React, { useState } from 'react'
import {
  useToast,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  InputGroup,
  InputRightElement,
  ModalCloseButton,
  VStack
} from '@chakra-ui/react'
import { FaPlus, FaTrash, FaUpload } from 'react-icons/fa'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

import { useProjectContext } from './ProjectContext'

interface CreateTrackProps {
  isOpen: boolean
  onClose: () => void
}

const CreateTrack = (props: CreateTrackProps) => {
  const [trackNames, setTrackNames] = useState<string[]>([''])
  const [files, setFiles] = useState<File[]>([new File([''], '')])
  const [creatingTrack, setCreatingTrack] = useState(false)

  const { currentProject, createTrack } = useProjectContext();

  const toast = useToast()
  const [user] = useAuthState(auth)


  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0] || null

    if (file) {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles]
        newFiles[index] = file
        return newFiles
      })
    }
  }

  const createTrackInternal = async () => {
    if (currentProject) {
      setCreatingTrack(true)
      const createResult = await createTrack(files, trackNames, currentProject)
      setCreatingTrack(false)
      props.onClose()

      if (createResult) {
        toast({
          title: 'Track Created',
          description: 'Your track has been created successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        setTrackNames([''])
        setFiles([])
      } else {
        toast({
          title: 'Error',
          description: 'There was an error creating the track. Please reload your page and try again.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  const addTrack = () => {
    setTrackNames([...trackNames, ''])
    setFiles([...files, new File([''], '')])
  }

  const removeTrack = (index: number) => {
    setTrackNames(trackNames.filter((_, i) => i !== index))
    setFiles(files.filter((_, i) => i !== index))
  }

  return user ? (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={8}>
          <VStack spacing={6}>
            <Heading as='h2' size='lg'>
              Create New Tracks
            </Heading>
            {trackNames.map((trackName, index) => (
              <Box key={index} w='100%'>
                <FormControl>
                  <FormLabel>Track {index + 1}</FormLabel>
                  <InputGroup>
                    <Input
                      value={trackName}
                      onChange={(e) => {
                        const newTrackNames = [...trackNames]
                        newTrackNames[index] = e.target.value
                        setTrackNames(newTrackNames)
                      }}
                      placeholder={`Track ${index + 1} name`}
                    />
                    <InputRightElement>
                      <Button
                        size='sm'
                        onClick={() => removeTrack(index)}
                        variant='ghost'
                        colorScheme='red'
                      >
                        <Icon as={FaTrash} />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mt={2}>
                  <Input
                    type='file'
                    accept='audio/*'
                    onChange={(e) => handleFileUpload(e, index)}
                    hidden
                    id={`file-input-${index}`}
                  />
                  <Button
                    as='label'
                    htmlFor={`file-input-${index}`}
                    leftIcon={<FaUpload />}
                    variant='outline'
                    colorScheme='orange'
                    w='100%'
                    isTruncated
                  >
                    {files[index] ? files[index].name : 'Upload Audio'}
                  </Button>
                </FormControl>
              </Box>
            ))}
            <Button
              leftIcon={<FaPlus />}
              onClick={addTrack}
              variant='ghost'
              colorScheme='green'
            >
              Add Track
            </Button>
            <Button
              colorScheme='blue'
              isLoading={creatingTrack}
              onClick={createTrackInternal}
              isDisabled={
                !trackNames ||
                trackNames.some((name) => !name) ||
                files.some((file) => !file.name)
              }
              w='100%'
            >
              Create Track
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={8}>
          <VStack spacing={6}>
            <Heading as='h2' size='lg'>
              Create New Tracks
            </Heading>
            <Button
              leftIcon={<FaPlus />}
              onClick={addTrack}
              variant='ghost'
              colorScheme='green'
            >
              Add Track
            </Button>
            <Button
              colorScheme='blue'
              isLoading={creatingTrack}
              onClick={createTrackInternal}
              isDisabled={
                !trackNames ||
                trackNames.some((name) => !name) ||
                files.some((file) => !file.name)
              }
              w='100%'
            >
              Create Track
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateTrack
