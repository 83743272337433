import React, { useState } from 'react'
import {
  Flex,
  Image,
  Spacer,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast
} from '@chakra-ui/react'
import emailjs from '@emailjs/browser'

const Footer: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    source: '',
    feedback: ''
  })
  const toast = useToast()

  const onClose = () => {
    setIsOpen(false)
    setFormData({ name: '', email: '', source: '', feedback: '' })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {
    const templateParams = {
      name: formData.name,
      email: formData.email,
      source: formData.source,
      feedback: formData.feedback
    }
    // TODO: setup backend api endpoint to send email
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID as string
    const userPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string

    const templateID = 'template_h40uzem'

    emailjs.send(serviceID, templateID, templateParams, {
      publicKey: userPublicKey
    })

    toast({
      title: 'Feedback sent successfully',
      description: 'Thank you for your feedback!',
      status: 'success',
      duration: 5000,
      isClosable: true
    })
    onClose()
  }

  return (
    <footer>
      <Flex
        direction={['column', 'column', 'row']}
        w='full'
        px={6}
        align='center'
        bg='transparent'
        py={2}
      >
        <Image src='/logo.svg' alt='Logo' width='150px' height='auto' />
        <Spacer />
        <Text fontSize='sm'>
          Copyright &copy; {new Date().getFullYear()} Soloflow, Inc.
        </Text>
        <Spacer />
        <Button
          onClick={() => setIsOpen(true)}
          variant='ghost'
          size='md'
          fontWeight='medium'
          bg='orange.400'
          color='white'
          _hover={{ bg: 'orange.500' }}
          transition='all 0.2s'
        >
          Questions / Feedback
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Leave Feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                name='name'
                value={formData.name}
                onChange={handleChange}
                placeholder='Your name'
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Email</FormLabel>
              <Input
                name='email'
                value={formData.email}
                onChange={handleChange}
                placeholder='your@email.com'
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>How did you hear about us?</FormLabel>
              <Input
                name='source'
                value={formData.source}
                onChange={handleChange}
                placeholder='e.g., Social media, friend, etc.'
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Feedback/Suggestion</FormLabel>
              <Textarea
                name='feedback'
                value={formData.feedback}
                onChange={handleChange}
                placeholder='Your feedback or suggestion'
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button type='submit' onClick={handleSubmit} colorScheme='blue' mr={3}>
              Submit
            </Button>
            <Button variant='ghost' onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </footer>
  )
}

export default Footer
