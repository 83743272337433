import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BASE_URL } from '../../config';
import { auth } from '../../firebase';
import * as amplitude from '@amplitude/analytics-browser'
import { Project } from '../../_helpers/types';
import { isProjectShared } from '../project/ProjectUtils';

// Define the User object structure
export interface User {
  uid: string;
  email: string;
  photo_url?: string;
  plan: 'FREE' | 'PRO' | 'ENTERPRISE';
  trial_end_date?: string;
  created_at: string;
  updated_at: string;
  feature_flags?: {
    enable_share_restriction: boolean;
  };
  max_shared_project_limit: number;
  editable_projects: {
    project_id: string;
    shared_at: Date
  }[];
  pending_projects: {
    project_id: string;
    shared_at: Date
  }[];
  stripe_customer_id?: string;
}

// Define the UserContext structure
interface UserContextType {
    soloflowUser: User | null;
    loading: boolean;
    error: string | null;
    refetchUser: () => Promise<void>;
    upgradeToPro: (stripeCustomerId: string) => Promise<void>;
    downgradeToFree: () => Promise<void>;
    updateLocalUser: (updates: Partial<User>) => void; // Direct local updates
    getUserPricingPlan: () => string | undefined;
    getNumberOfSharedProjects: () => number | undefined;
    canUserEditSharedProject: (project: Project | undefined | null) => boolean;
    canUserShareProject: (project: Project | undefined) => boolean;
    isUpgradeModalOpen: boolean;
    openUpgradeModal: () => void;
    closeUpgradeModal: () => void;
    isSignInModalOpen: boolean;
    openSignInModal: () => void;
    closeSignInModal: () => void;
    openRelevantModal: () => void;
  }
  
  const UserContext = createContext<UserContextType | null>(null);
  
  /* eslint-disable @typescript-eslint/no-explicit-any */
  export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [soloflowUser, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [authUser] = useAuthState(auth);

    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
    const [isSignInModalOpen, setIsSignInModalOpen] = useState(false)

    const openUpgradeModal = () => {
      setIsUpgradeModalOpen(true)
      amplitude.track('Upgrade Modal Opened', {
        userID: soloflowUser?.uid || 'Invitee'
      })
    }
    const closeUpgradeModal = () => setIsUpgradeModalOpen(false)
    
    const openSignInModal = () => {
      if (!authUser) {
        setIsSignInModalOpen(true)
      }
    }
    const closeSignInModal = () => setIsSignInModalOpen(false)
  
    const openRelevantModal = () => {
      if (!authUser) {
        openSignInModal()
      } else {
        openUpgradeModal()
      }
    }

    const fetchUser = async () => {
      if (!authUser) return;
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(`${BASE_URL}/users/${authUser.uid}`);
        setUser(response.data);
        console.log(soloflowUser)
      } catch (err: any) {
        console.error('Error fetching soloflowUser:', err);
        setError(err.response?.data?.message || 'Failed to fetch soloflowUser data');
      } finally {
        setLoading(false);
      }
    };

    const canUserShareProject = (project: Project | undefined): boolean => {
      if (!soloflowUser || !project) return false
      if (isProjectShared(project)) return true
      if (soloflowUser.plan === "PRO" || soloflowUser.plan === "ENTERPRISE") return true 
      if (soloflowUser.plan === "FREE" && soloflowUser.pending_projects.length > 0) return false
      return true;
    }

    const canUserEditSharedProject = (project: Project | undefined | null): boolean => {
      if (soloflowUser && project) {
        // If not a shared project, no restriction to editing
        if (!isProjectShared(project)) {
          return true;
        }
    
        // Pro users have no restriction
        if (soloflowUser.plan === "PRO" || soloflowUser.plan === "ENTERPRISE") {
          return true;
        }
    
        // Free users can edit all projects if they are within their limit
        if (
          soloflowUser.plan === "FREE" &&
          soloflowUser.editable_projects.length < soloflowUser.max_shared_project_limit &&
          soloflowUser.pending_projects.length === 0
          // && soloflowUser.editable_projects.some((p) => p.projectID === project.id)
        ) {
          return true;
        }
    
        // Free users: Check their editable_projects
        const isEditable = soloflowUser.editable_projects.some(
          (p) => p.project_id === project.id
        );


        if (isEditable) {
          return true; // Project is within the editable_projects collection
        }

        // If the project is in pending_projects, editing requires an upgrade
        const isPending = soloflowUser.pending_projects.some(
          (p) => p.project_id === project.id
        );

        if (isPending) {
          return false; // Requires an upgrade to edit
        }

        // Final fallback for free users
        return false;
      }
    
      return false;
    };
    
  
    // Function to update the local soloflowUser object
    const updateLocalUser = (updates: Partial<User>) => {
      setUser((prev) => (prev ? { ...prev, ...updates, updated_at: new Date().toISOString() } : prev));
    };
  
    const upgradeToPro = async (stripeCustomerId : string) => {
      console.log("User: ", soloflowUser)
      if (!authUser) return;
      try {
        const response = await axios.put(`${BASE_URL}/users/${authUser.uid}/${stripeCustomerId}/upgrade`);
        if (response.data.code === 200) {
          updateLocalUser({ plan: 'PRO' }); // Update locally
        } else {
          setError(response.data.message);
        }
        fetchUser();
      } catch (err: any) {
        console.error('Error upgrading plan:', err);
        setError(err.response?.data?.message || 'Failed to upgrade plan');
      }
    };
  
    const downgradeToFree = async () => {
      if (!soloflowUser) return;
      try {
        const response = await axios.put(`${BASE_URL}/users/${soloflowUser.uid}/downgrade`);
        if (response.data.code === 200) {
          updateLocalUser({ plan: 'FREE' }); // Update locally
        } else {
          setError(response.data.message);
        }
      } catch (err: any) {
        console.error('Error downgrading plan:', err);
        setError(err.response?.data?.message || 'Failed to downgrade plan');
      }
    };

    const getUserPricingPlan = () => {
      if (!soloflowUser) return;
      return soloflowUser.plan;
    }

    const getNumberOfSharedProjects = () => {
      if (!soloflowUser) return;
      // return soloflowUser.shared_projects;
      return (soloflowUser.editable_projects??[]).length + (soloflowUser.pending_projects??[]).length
    }
  
    useEffect(() => {
      if (authUser) {
        fetchUser();
      } else {
        setUser(null);
        setLoading(false);
      }
    }, [authUser]);
  
    return (
      <UserContext.Provider
        value={{
          soloflowUser,
          loading,
          error,
          refetchUser: fetchUser,
          upgradeToPro,
          downgradeToFree,
          updateLocalUser,
          getUserPricingPlan,
          getNumberOfSharedProjects,
          canUserEditSharedProject,
          canUserShareProject,
          isUpgradeModalOpen,
          openUpgradeModal,
          closeUpgradeModal,
          isSignInModalOpen,
          openSignInModal,
          closeSignInModal,
          openRelevantModal,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  };

// Custom hook to consume UserContext
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
