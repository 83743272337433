import React from 'react'
import { ChakraProvider, extendTheme, Box, Flex, Heading, Text, Button, Icon, Image} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { FaExclamationTriangle, FaHome } from 'react-icons/fa'

// Define types for our theme (reuse the theme from LandingPage)
type ThemeColors = {
  [key: string]: {
    [key: number]: string
  }
}

const themeColors: ThemeColors = {
  brand: {
    50: '#FFF5E6',
    100: '#FFE0B2',
    200: '#FFCC80',
    300: '#FFB74D',
    400: '#FFA726',
    500: '#FF9800',
    600: '#FB8C00',
    700: '#F57C00',
    800: '#EF6C00',
    900: '#E65100'
  }
}

const theme = extendTheme({
  colors: themeColors,
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Inter", sans-serif'
  }
})

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <ChakraProvider theme={theme}>
      <Box as='nav' bg='white' boxShadow='sm' position='sticky' top={0} zIndex={10}>
          <Flex
            mx='auto'
            px={4}
            align='center'
            justify='space-between'
          >
            <Flex align='center'>
              <Image src='/logo.svg' alt='Soloflow Logo' height='60px'/>
            </Flex>
          </Flex>
        </Box>
      <Box
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgGradient="linear(to-b, #FFE0B2, #FFF5E6)"
        color="white"
      >
        <Flex
          direction="column"
          align="center"
          textAlign="center"
          p={8}
          bg="rgba(0,0,0,0.6)"
          borderRadius="lg"
          maxWidth="600px"
        >
          <motion.div
        initial={{ rotate: -10 }}
        animate={{ rotate: 10 }}
        transition={{ repeat: Infinity, duration: 1, repeatType: "reverse" }}
          >
        <Icon as={FaExclamationTriangle} w={20} h={20} mb={4} color="brand.500" />
          </motion.div>
          <Heading
        as={motion.h1}
        size="2xl"
        mb={4}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
          >
        404: Page Not Found
          </Heading>
          <Text
        fontSize="xl"
        mb={8}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        as={motion.p}
          >
        Oops! We could not find the page you were looking for
          </Text>
          <Button
        as={motion.button}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        size="lg"
        colorScheme="orange"
        bg="white"
        color="brand.700"
        _hover={{
          color: 'brand.800'
        }}
        onClick={() => navigate('/')}
        leftIcon={<FaHome />}
          >
        Back to the Main Stage
          </Button>
        </Flex>
      </Box>
    </ChakraProvider>
  )
}

export default NotFoundPage