/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react'
import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Input,
  Text,
  Box,
  HStack,
  Spinner,
  IconButton,
  Textarea,
  VStack,
  Icon,
  //keyframes,
  Tooltip
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import axios from 'axios'
import { FaPaperPlane, FaMagic, FaPencilAlt } from 'react-icons/fa'
//import { motion } from 'framer-motion'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'

import { auth } from '../../firebase'
import { BASE_URL } from '../../config'
import { socket } from '../../App'
import { useNotification } from '../common/NotificationContext'
import { useProjectContext } from '../project/ProjectContext'
import { useUser } from '../common/UserContext'

const ThreadModal = ({
  isOpen,
  onClose,
  region,
  threadParam,
  filePath,
  isOwner,
}) => {
  // const pulseAnimation = keyframes`
  //   0% { transform: scale(1) rotate(0); }
  //   12.5% { transform: scale(1.05) rotate(5deg); }
  //   25% { transform: scale(1) rotate(0); }
  //   37.5% { transform: scale(1.05) rotate(-5deg); }
  //   50% { transform: scale(1) rotate(0); }
  //   62.5% { transform: scale(1.05) rotate(-5deg); }
  //   75% { transform: scale(1) rotate(0); }
  //   87.5% { transform: scale(1.05) rotate(-5deg); }
  //   100% { transform: scale(1) rotate(0); }
  // `

  const [user] = useAuthState(auth)
  const [currentThread, setCurrentThread] = useState()
  const [currentThreadIsResolved, setCurrentThreadIsResolved] = useState(false)
  const [creatingMessage, setCreatingMessage] = useState(false)

  const [newMessage, setNewMessage] = useState('')
  const [aiResponse, setAIResponse] = useState([])
  const [aiLoading, setAILoading] = useState(false)
  const [lastUserMessage, setLastUserMessage] = useState('')
  const timerRef = useRef(null)
  const toast = useToast()

  const modalBodyRef = useRef(null)

  const { unreadNotifications, markRelatedNotificationsAsRead } = useNotification();  // Use notification context
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)

  const { currentProject, currentVersion, handleNewMessage, updateThread, createThread, toggleThreadResolved } = useProjectContext()
  const { canUserEditSharedProject, openRelevantModal } = useUser()

  const previousMessageCount = useRef(currentThread?.messages.length);
  const previousThreadId = useRef(currentThread?.id);
  useEffect(() => {
    if (modalBodyRef.current && currentThread && currentThread.messages.length > 0) {
      const messageCount = currentThread.messages.length;
      if (messageCount === previousMessageCount.current + 1 && currentThread.id == previousThreadId.current) {
        modalBodyRef.current.scrollTo({
          top: modalBodyRef.current.scrollHeight,
          behavior: 'smooth',
        });
      } else {
        modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
      }
      previousMessageCount.current = messageCount;
      previousThreadId.current = currentThread.id;
    }
  }, [aiResponse, currentThread?.messages.length, modalBodyRef.current]);


  useEffect(() => {
    if (threadParam) {
      setCurrentThread(threadParam)
      setCurrentThreadIsResolved(threadParam.isResolved)
    } else {
      const newThread = {region: region, messages: [], isResolved: false, versionID: currentVersion?.id}
      setCurrentThread(newThread)
      setCurrentThreadIsResolved(false)
    }
  }, [threadParam])

  useEffect(() => {
    if (aiResponse.length > 0) {
      timerRef.current = setTimeout(() => {
        setAILoading(false)
      }, 1000)
    }

    return () => clearTimeout(timerRef.current)
  }, [aiResponse])

  useEffect(() => {
    if (isOpen) {
      if (currentThread?.id) {
        socket.emit('join_thread', threadParam.id);

        // Listen for real-time message updates
        // socket.on('new_thread_message', handleNewMessage);
      } 
    }
  }, [currentThread]);


  useEffect(() => {
    if (isOpen) {
      if (currentThread.id) {
        // Join the room for this threadID to listen for real-time updates
        socket.emit('join_thread', currentThread.id);
        console.log("Joined thread " + currentThread.id)

        socket.on('new_thread_message', handleNewMessage);
        console.log("Listening for new messages")
      }
    } 

    return () => {
      // We will leave the thread room whenever the thread window is closed
      if (currentThread?.id) {
        socket.emit('leave_thread', currentThread.id);  
        console.log("Left thread" + currentThread.id)
      }

      // When we leave the room, we want to stop listening for new_thread_messages
      socket.off('new_thread_message', handleNewMessage);  
    };
  }, [isOpen])
  

  /**
   * This method is called whenever the user either creates a new thread
   * or updates an existing thread by adding a comment to it.
   * @param {} newMessages 
   * @returns The updated thread with a list of messages
   */
  const updateOrCreateThread = async (newMessages) => {
    const messageBody = newMessages.map((message) => ({
      content: message.content,
      sender: message.sender,
      timestamp: message.timestamp.toLocaleString()
    }))

    let threadOperationResult
    if (currentThread?.id) {
      threadOperationResult = await updateThread(currentThread, messageBody)
    } else {
      setCreatingMessage(true)
      currentThread.messages = messageBody
      currentThread.region = region
      region.remove()
      threadOperationResult = await createThread(currentThread, messageBody, region)
      setCreatingMessage(false)
    }

    if (!threadOperationResult) {
      toast({
        title: 'Failed to update thread. Please reload your page and try again.',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (currentProject && !canUserEditSharedProject(currentProject)) {
      openRelevantModal();
      setNewMessage('');
      return
    }

    if (newMessage.trim() && !currentThreadIsResolved && !creatingMessage) {
      const newMessageObj = [{
        content: newMessage,
        sender: user?.email || localStorage.getItem('userEmail') || 'Anonymous',
        timestamp: new Date()
      }]

      setNewMessage('')

      try {
        setUnreadMessagesCount((prev) => prev+1)
        await updateOrCreateThread(newMessageObj);

        // Create a send_thread_message event for the backend to process and broadcast a new_thread_message event for listeners
        socket.emit('send_thread_message', {"threadId": currentThread.id, "message":newMessageObj})

        try {
          const notificationObject = {
            "threadID": currentThread.id, 
            "message":newMessageObj,
            "versionID": currentVersion?.id, 
            "senderUID": user?.uid || 'Anonymous',
            "senderEmail": user?.email || 'Anonymous',
          }
          
          socket.emit('send_thread_notifications', notificationObject)
          await axios.post(`${BASE_URL}/notifications/thread`, notificationObject);
        } catch (error) {
          console.error('Error creating notifications:', error);
        }
        
      } catch (error) {
        console.error('Failed to send message:', error);
        toast({
          title: 'Failed to send message',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    }
  };

  const handleAISuggestion = async () => {
    if (newMessage.trim()) {
      setAIResponse([])
      setLastUserMessage(newMessage)
      setAILoading(true)

      const req_body = `${newMessage} | Timestamp: ${region.start} - ${region.end}`

        // FOR DEMO PURPOSES ONLY ---->
        if (newMessage.includes('energy')) {
          const sampleResp = [
            'Would you be able to inject more energy by using a more upbeat tone and delivery for this segment?',
            'Can you increase the pace and infuse more enthusiasm into this section?',
            'Could you add more dynamic variation and liveliness to this part?'
          ]

          setAIResponse([...sampleResp])
          setAILoading(false)
          return
        } else if (newMessage.includes('pop')) {
          const sampleResp = [
            'Can you consider incorporating some upbeat music or sound effects to heighten the excitement of this segment?',
            'Could you consider varying the pacing or intensity in this part to create a more exciting atmosphere?',
            'I think adding more dynamic range to this part could make it more exciting.'
          ]

          setAIResponse([...sampleResp])
          setAILoading(false)
          return
        }
        // FOR DEMO PURPOSES ONLY <----

      try {
        const response = await axios.post(`${BASE_URL}/threads/ai`, {
          // TODO: make file analysis faster and figure out why filePath is undefined
          file_path: filePath || undefined,
          message: req_body
        })

        const rawResp = JSON.parse(response.data)

        // Parse the response (json format) to an array of strings
        const apiResp = rawResp.map((resp) => {
          return resp.message
        })

        setAIResponse([...apiResp])

        // Track the AI suggestion generated
        // TODO: Should we make this more specific (by thread, version, etc.)?
        amplitude.track('AI Suggestion Generated', {
          userID: user?.uid
        })
      } catch (error) {
        console.error('Error generating AI response:', error)
        toast({
          title: 'Failed to generate AI suggestions',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      } finally {
        setAILoading(false)
      }
    }
  }

  const handleAIUpdate = async (aiMsg) => {
    const newMessage = [
    {
      content: aiMsg,
      sender: user?.email || localStorage.getItem('userEmail') || 'Anonymous',
      timestamp: new Date()
    }]
    
    setNewMessage('')
    setAIResponse([])

    // Track the AI suggestion used
    // TODO: Should we make this more specific (by thread, version, etc.)?
    amplitude.track('AI Suggestion Used', {
      userID: user?.uid
    })

    // Update the thread with the new AI message
    await updateOrCreateThread(newMessage)
  }

  useEffect(() => {
    if (isOpen && currentThread.id && user) {
      setUnreadMessagesCount(unreadNotifications[currentThread.id])
      markRelatedNotificationsAsRead(currentThread.id, user.uid, toast);
    }

  }, [isOpen, currentThread, user]);

  
  const toggleThreadResolve = async () => {
    if (!canUserEditSharedProject(currentProject)) {
      openRelevantModal()
      return false
    }
    
    const currentlyResolved = currentThreadIsResolved
    setCurrentThreadIsResolved(!currentThreadIsResolved)

    const successToastId = toast({
      title: currentlyResolved ? 'Thread reopened!': 'Thread resolved!',
      status: 'success',
      duration: 3000,
      isClosable: true
    })
    const toggleThreadResolveResult = await toggleThreadResolved(currentThread.id)

    if (!toggleThreadResolveResult) {
      if (successToastId) {
        toast.close(successToastId)
      }
      toast({
        title: `Failed to toggle thread resolution. Please reload your page and try again.`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }

    if (!currentlyResolved) {
      handleClose()
    }
  }

  

  const handleClose = () => {
    setNewMessage('')
    setAIResponse([])

    onClose()
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage(e)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='xl'>
      <ModalOverlay />
      <ModalContent bg='white' color='gray.800' minWidth='70vw' maxHeight='80vh'>
        <ModalHeader>
          <Flex justifyContent='space-between' alignItems='center'>
            <Text fontSize='2xl' fontWeight='bold'>
              Conversation
            </Text>
            <Flex alignItems='center'>
              {isOwner && (
                <Button
                  size='sm'
                  variant='outline'
                  colorScheme={currentThreadIsResolved?'red':'green'}
                  isDisabled={currentThread?.id === undefined}
                  onClick={toggleThreadResolve}
                  leftIcon={ currentThreadIsResolved?<FaPencilAlt />:<CheckIcon />}
                  mr={2}
                >
                  {currentThreadIsResolved? "Reopen Thread" : "Resolve"}
                </Button>
              )}
              <ModalCloseButton position='static' />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody overflowY='auto' ref={modalBodyRef}>
          <VStack spacing={4} align='stretch'>
            {currentThread?.messages.map((message, index) => {
              const isUserMessage =
                message.sender ===
                (user?.email || localStorage.getItem('userEmail') || 'Anonymous')
              return (
                <><Flex justifyContent='center'>
                  {user && unreadMessagesCount > 0 && index === currentThread?.messages.length - unreadMessagesCount && (
                    <Box width='100%' borderBottom='1px solid' borderColor='gray.300' py={2} mb={2}>
                      <Text fontSize='sm' color='gray.500' textAlign='center'>
                        Unread Messages
                      </Text>
                    </Box>
                  )}
                </Flex><><Flex
                  key={index}
                  justifyContent={isUserMessage ? 'flex-end' : 'flex-start'}
                >
                  <Box
                    maxWidth='70%'
                    bg={isUserMessage ? 'blue.500' : 'gray.100'}
                    color={isUserMessage ? 'white' : 'black'}
                    p={3}
                    borderRadius='lg'
                    borderTopRightRadius={isUserMessage ? 0 : 'lg'}
                    borderTopLeftRadius={isUserMessage ? 'lg' : 0}
                  >
                    {!isUserMessage && <Text fontWeight='bold'>{message.sender}</Text>}
                    <Text
                      fontSize='sm'
                      color={isUserMessage ? 'blue.100' : 'gray.500'}
                      mb={1}
                    >
                      {message.timestamp.toLocaleString()}
                    </Text>
                    <Text>{message.content}</Text>
                  </Box>
                </Flex></></>
                
              )
            })}
          </VStack>
          {aiResponse.length !== 0 && (
            <Box justifySelf='center'>
              {aiLoading ? (
                <Spinner thickness='3px' color='orange.500' size='lg' />
              ) : (
                <VStack align='stretch' spacing={3}>
                  <Text fontSize='lg' fontWeight='bold'>
                    Select a response that best matches your feedback:
                  </Text>
                  <HStack spacing={4} alignItems='stretch' overflowX='auto' pb={2}>
                    <AIResponseBox
                      response={lastUserMessage}
                      handleAIUpdate={handleAIUpdate}
                      aiGenerated={false}
                    />
                    {aiResponse.map((response, index) => (
                      <AIResponseBox
                        key={index}
                        response={response}
                        handleAIUpdate={handleAIUpdate}
                        aiGenerated={true}
                      />
                    ))}
                  </HStack>
                </VStack>
              )}
            </Box>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack width='100%' spacing={2}>
            <Input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyUp={handleKeyPress}
              placeholder={currentThreadIsResolved
                ? 'Thread is resolved – Reopen to edit': 'Type your message...'}
              flex={1}
              isDisabled={currentThreadIsResolved} />
            <Tooltip label='COMING SOON: Translate your ideas into actionable suggestions!' hasArrow>
              <Button
                // as={motion.button}
                // animation={`${pulseAnimation} 5s`}
                onClick={handleAISuggestion}
                bgColor='grey'
                leftIcon={<Icon as={FaMagic} />}
                isDisabled={true || currentThreadIsResolved|| !newMessage.trim()} // Disabled AI for now
              >
                Generate Suggestions
              </Button>
            </Tooltip>
            <IconButton
              icon={<FaPaperPlane />}
              colorScheme='blue'
              isLoading={creatingMessage}
              onClick={handleSendMessage}
              isDisabled={currentThreadIsResolved|| !newMessage.trim() || creatingMessage}
            />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const AIResponseBox = ({ response, handleAIUpdate, aiGenerated }) => {
  const [editResponse, setEditResponse] = useState(response)
  const textareaRef = useRef(null)

  const handleSaveClick = () => {
    handleAIUpdate(editResponse)
  }

  const textAreaChange = (e) => {
    setEditResponse(e.target.value)
    adjustTextareaHeight()
  }

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
    }
  }

  return (
    <Box
      bg={aiGenerated ? 'orange.100' : 'gray.100'}
      p={3}
      borderRadius='lg'
      borderTopLeftRadius={0}
      minWidth='200px'
      maxWidth='300px'
      flexShrink={0}
    >
      <Text fontWeight='bold' mb={1}>
        {aiGenerated ? 'AI Suggestion' : 'Original Message'}
      </Text>
      <Text fontSize='sm' color='gray.500' mb={2}>
        {new Date().toLocaleString()}
      </Text>
      <Textarea
        ref={textareaRef}
        value={editResponse}
        onChange={textAreaChange}
        onInput={adjustTextareaHeight}
        size='sm'
        variant='filled'
        resize='none'
        minH='100px'
        mb={2}
        bg='white'
      />
      <Flex justifyContent='flex-end'>
        <IconButton
          icon={<CheckIcon />}
          size='sm'
          colorScheme='green'
          onClick={handleSaveClick}
        />
      </Flex>
    </Box>
  )
}

export default ThreadModal