import React, { createContext, useContext, useState, useEffect, ReactNode, useRef } from 'react';
import axios from 'axios';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, storage } from '../../firebase';
import { BASE_URL } from '../../config';
import { Comment, Project, Thread, Track, Version } from '../../_helpers/types';
import { socket } from '../../App';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import * as amplitude from '@amplitude/analytics-browser'
import { useToast } from '@chakra-ui/react';
import { Region } from 'wavesurfer.js/src/plugin/regions';

interface ProjectContextType {
  projects: Project[];
  currentProject: Project | null;

  tracks: Track[] | null;
  currentTrack: Track | null;

  versions: Version[] | null;
  currentVersion: Version | null;
  currentAudioUrl: string | null;

  threads: Thread[] | null;

  loadingData: boolean;
  loadingError: Error | null;

  setCurrentProjectId: React.Dispatch<React.SetStateAction<string | null>>;
  setCurrentTrackId: React.Dispatch<React.SetStateAction<string | null>>;
  setCurrentVersionId: React.Dispatch<React.SetStateAction<string | null>>;

  createProject: (files: File[], projectName: string, trackNames: string[]) => Promise<string | null>;
  updateProject: (projectId: string, updatedParameters: Partial<Project>) => Promise<boolean>;
  deleteProject: (projectId: string) => Promise<boolean>;
  renameProject: (projectId: string, newName: string) => Promise<boolean>;
  refetchProject: (projectId: string) => Promise<boolean>;
  completeProject: (projectId: string) => Promise<boolean>;
  activateProject: (projectId: string) => Promise<boolean>;
  saveProject: (projectId: string) => Promise<boolean>;

  createTrack: (files: File[], trackNames: string[], projectToAppend: Project) => Promise<boolean>;
  deleteTrack: (trackId: string) => Promise<boolean>;
  renameTrack: (trackId: string, newTrackName: string) => Promise<boolean>;
  sendComment: (newComment: Comment) => Promise<boolean>;
  handleNewComment: (newComment: Comment) => boolean;

  createVersion: (file: File, versionName: string, trackId: string) => Promise<boolean>;
  markFinalVersion: (versionId: string) => Promise<boolean>;
  unmarkFinalVersion: (versionId: string) => Promise<boolean>;
  renameVersion: (versionId: string, newVersionName: string) => Promise<boolean>;
  deleteVersion: (versionId: string) => Promise<boolean>;

  toggleThreadResolved: (threadId: string) => Promise<boolean>;
  createThread: (currentThreadOutline: Thread, messageBody: Comment[], region: Region) => Promise<boolean>;
  updateThread: (threadToUpdate: Thread, newMessages: Comment[]) => Promise<boolean>;
  handleNewMessage: (threadId: string, newMessage: Comment) => boolean;
  deleteThread: (threadId: string) => Promise<boolean>;
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const ProjectProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [projects, setProjects] = useState<Project[]>([]);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);

  const [tracks, setTracks] = useState<Track[] | null>(null);
  const [currentTrack, setCurrentTrack] = useState<Track | null>(null);

  const [versions, setVersions] = useState<Version[] | null>(null);
  const [currentVersion, setCurrentVersion] = useState<Version | null>(null);

  const [currentAudioUrl, setCurrentAudioUrl] = useState<string | null>(null);

  const [threads, setThreads] = useState<Thread[] | null>(null);

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<Error | null>(null);

  const [currentProjectId, setCurrentProjectId] = useState<string | null>(null);
  const [currentTrackId, setCurrentTrackId] = useState<string | null>(null);
  const [currentVersionId, setCurrentVersionId] = useState<string | null>(null);

  const [user] = useAuthState(auth);

  const location = useLocation()
  const navigate = useNavigate()
  const toast = useToast()

  // ------------------------------------------------------------------------------------------------------------------
  // Projects Tools

  const createProject = async (files: File[], projectName: string, trackNames: string[]) => {
    setLoadingState(true)
    try {
      if (files.length >= 0 && user) {
        const createdProject : Project = {
          "name": projectName,
          "userID": user?.uid || '',
          "userIDs": [user?.uid],
          "clientEmails": [],
          "createdAt": new Date().toISOString(),
          "isComplete": false,
          "numTracks": trackNames.length,
          "createdBy": user.email || '',
          "id": ''
        }

        const projectResponse = await axios.post(`${BASE_URL}/projects`, {
          name: projectName,
          numTracks: trackNames.length,
          userIDs: [user?.uid],
          clientEmails: [],
          createdBy: user.email,
        })

        const { id: projectID } = projectResponse.data
        createdProject["id"] = projectID

        await createTrack(files, trackNames, createdProject)

        // After successful project creation
        amplitude.track('Project Created', {
          projectName: projectName,
          numTracks: trackNames.length,
          userID: user?.uid
        })

        setProjects((prevProjects) =>
          prevProjects ? [...prevProjects, createdProject] : [createdProject]
        );
        setCurrentProjectId(projectID)
        setLoadingState(false)
        return projectID
      } else {
        console.error('Error creating project:', 'No files or user')
        setLoadingState(false)
        return null
      }
    } catch (error) {
      console.error('Error creating project: ', error)
      setLoadingState(false)
      return null
    }
  }

  const updateProject = async (
    projectId: string,
    updatedParameters: Partial<Project>
  ): Promise<boolean> => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId ? { ...project, ...updatedParameters } : project
      )
    );
  
    return axios
      .put(`${BASE_URL}/projects/${projectId}`, updatedParameters)
      .then(() => {
        amplitude.track('Project Parameters Updated', {
          projectID: projectId,
          updatedParameters,
          userID: user?.uid,
        });
        return true;
      })
      .catch((error) => {
        console.error('Error updating project parameters:', error);
        return false;
      });
  };
  

  const deleteProject = async(projectId: string) => {
    setProjects((prevProjects) =>
      prevProjects.filter((project) => project.id !== projectId)
    );

    return axios.delete(`${BASE_URL}/projects/${projectId}`).then(() => {
      amplitude.track('Project Deleted', {
        projectID: projectId,
        userID: user?.uid
      })
      return true
    }).catch((error) => {
      console.error('Error deleting project:', error)
      return false
    })
  }

  const renameProject = async(projectId: string, newName: string) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId ? { ...project, name: newName } : project
      )
    );

    return axios.put(`${BASE_URL}/projects/${projectId}`, {
      name: newName,
    }).then(() => { 
      amplitude.track('Project Renamed', {
        projectID: projectId,
        newName: newName,
        userID: user?.uid
      })
      return true
    }).catch((error) => {
      console.error('Error renaming project:', error)
      return false
    })
  };

  const refetchProject = async(projectId: string) => {
    setLoadingState(true)
    return axios
      .get(`${BASE_URL}/projects/${projectId}`)
      .then((response) => {
        const newProject: Project = response.data
        setProjects((prevData) => {
          if (prevData.some((project) => project.id === newProject.id)) {
            return prevData
          }
          return [...prevData, newProject]
        })
        setLoadingState(false)
        return true
      })
      .catch((error) => {
        setLoadingState(false)
        console.error('Error refetching project:', error)
        return false
      })
  };

  const completeProject = async(projectId: string) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId ? { ...project, isComplete: true } : project
      )
    );

    return axios.put(`${BASE_URL}/projects/${projectId}`, {
      isComplete: true
    }).then(() => {
      amplitude.track('Project Completed', { 
        projectID: projectId,
        userID: user?.uid
      })
      return true
    }).catch((error) => {
      console.error('Error completing project:', error)
      return false
    })
  }

  const activateProject = async(projectId: string) => {
    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === projectId ? { ...project, isComplete: false } : project
      )
    );

    return axios.put(`${BASE_URL}/projects/${projectId}`, {
      isComplete: false
    }).then(() => {
      amplitude.track('Project Activated', {
        projectID: projectId,
        userID: user?.uid
      })
      return true
    }).catch((error) => {
      console.error('Error activating project:', error)
      return false
    })
  }

  const saveProject = async(projectId: string) => {
    return axios.post(`${BASE_URL}/projects/save`, {
      projectID: projectId,
      userID: user?.uid
    }).then(() => { 
      amplitude.track('Project Saved', {
        projectID: projectId,
        userID: user?.uid
      })
      return true
    }) 
    .catch((error) => {
      console.error('Error saving project:', error)
      return false
    })
  }
  
  const addUserToProject = async (userId: string, projectId: string): Promise<void> => {
    try {
      const response = await axios.put(`${BASE_URL}/projects/${projectId}/add_user`, { "user_id": userId, "project_id": projectId });
      if (response.status === 200) {
        toast({
          title: 'Access Granted',
          description: 'You have been added to the project.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else if (response.status === 204) {
        // Owner of project
      } else {
        toast({
          title: 'Error',
          description: 'Could not add project. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.error("Error adding user to project:", error);
      throw error;
    }
  };

  useEffect(() => {
    const getCurrentProject = () => {
      if (currentProjectId == null) {
        return null;
      }

      const project = projects.find((project) => project.id === currentProjectId);
      if (project == undefined) {
        setLoadingState(true)
        axios.get(`${BASE_URL}/projects/${currentProjectId}`)
        .then((response) => {
          setCurrentProject(response.data)
          setProjects((prevProjects) => {
            if (prevProjects.some((project) => project.id === response.data.id)) {
              return prevProjects
            }
            return [...prevProjects, response.data]
          })
        }).catch((error) => {
          console.error('Error fetching project:', error)
        }).finally(() => { 
          setLoadingState(false)
        })
        return null;
      }
      return project
    }

    setCurrentProject(getCurrentProject())
  }, [currentProjectId, projects])

  // ------------------------------------------------------------------------------------------------------------------
  // Tracks Tools

  const createTrack = async (files: File[], trackNames: string[], projectToAppend: Project) => {
    trackNames = JSON.parse(JSON.stringify(trackNames));

    try {
      if (files && user && projectToAppend) {
        const newTracks : Track[] = []
        for (let i = 0; i < trackNames.length; i++) {
          const track = await axios.post(`${BASE_URL}/tracks`, {
            name: `${trackNames[i]}`,
            projectID: projectToAppend.id
          })

          tracks?.filter((oldTrack) => oldTrack.id !== track.data.id)

          await createVersion(files[i], 'Version 1', track.data.id)

          amplitude.track('Track Created', {
            trackName: trackNames[i],
            projectID: projectToAppend.id,
            userID: user?.uid
          })

          newTracks.push(track.data)
        }

        const updatedNumTracks = projectToAppend.numTracks + trackNames.length
        const updatedProject = {
          name: projectToAppend.name,
          numTracks: updatedNumTracks,
          // userIDs: props.project.userID,
          clientEmails: projectToAppend.clientEmails
        }
        await axios.put(`${BASE_URL}/projects/${projectToAppend.id}`, updatedProject)

        setTracks((prevTracks) =>
          prevTracks ? [...prevTracks, ...newTracks] : newTracks
        )

        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project.id === projectToAppend.id ? { ...project, numTracks: updatedNumTracks } : project
          )
        )
        return true
      } else {
        console.error('Error creating track:', 'No files or user')
        return false
      }
    } catch (error) {
      console.error('Error creating track:', error)
      return false
    }
  }

  const deleteTrack = async (trackId: string) => {
    setTracks((prevTracks) =>
      prevTracks ? prevTracks.filter((track) => track.id !== trackId) : null
    );

    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === currentProjectId
          ? { ...project, numTracks: (project.numTracks || 0) - 1 }
          : project
      )
    );

    return axios
      .delete(`${BASE_URL}/tracks/${trackId}`)
      .then(() => {
        amplitude.track('Track Deleted', {
          trackID: trackId,
          userID: user?.uid
        })
        return true
      }).catch((error) => {
        console.error('Error deleting tracks:', error)
        return false
      })
  }

  const renameTrack = async (trackId: string, newTrackName: string) => {
    setTracks((prevTracks) =>
      prevTracks ? prevTracks.map((track) =>
        track.id === trackId ? { ...track, name: newTrackName } : track
      ) : null)

    return axios
      .put(`${BASE_URL}/tracks/${trackId}`, {
        name: newTrackName
      })
      .then(() => {
        amplitude.track('Track Renamed', {
          trackID: trackId,
          newName: newTrackName,
          userID: user?.uid
        })
        return true
      }).catch((error) => {
        console.error('Error renaming track:', error)
        return false
      })
  }

  const sendComment = async (newComment: Comment) => {
    if (currentTrack) {
      currentTrack.generalComments = currentTrack.generalComments ? [...currentTrack.generalComments, newComment] : [newComment]

      return axios.put(`${BASE_URL}/tracks/${currentTrack.id}`, {
        generalComments: [
          ...(currentTrack.generalComments || [])        
        ]
      }).then(() => {
        socket.emit("send_chat_message", {"trackID":currentTrack.id, "message":newComment})

        amplitude.track('General comment sent', {
          userID: user?.uid || 'Invitee'
        })
        return true
      }).catch((error) => {
        console.error('Error creating comment:', error)
        return false
      })
    }

    console.error('Error creating comment:', 'No current track')
    return false
  }

  const handleNewComment = (newComment: Comment) => {
    if (currentTrack) {
      setTracks((prevTracks) =>
        prevTracks ? prevTracks.map((track) =>
          track.id === currentTrackId ? { ...track, generalComments: [...(track.generalComments || []), newComment] } : track
        ) : null
      )
      return true
    } else {
      console.error('Error creating comment:', 'No current track')
      return false
    }
  }

  useEffect(() => {
    const getCurrentTrack = () => {
      if (currentTrackId == null) {
        return null;
      }

      const track = tracks?.find((track) => track.id === currentTrackId);
      if (track == undefined) {
        return null;
      }
      return track
    }

    setCurrentTrack(getCurrentTrack())
  } , [currentTrackId, tracks])

  // ------------------------------------------------------------------------------------------------------------------
  // Versions Tools

  const setVersionsAndSort = (unsortedVersions: Version[]) => {
    const sortedVersions = [...unsortedVersions].sort((a, b) => {
      if (a.isFinal !== b.isFinal) {
        return a.isFinal ? -1 : 1; // Prioritize `isFinal` versions
      }
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); // Sort by creation date (descending)
    });
    setVersions(sortedVersions); // Update state with the sorted array
  }

  const createVersion = async (file: File, versionName: string, trackId: string) => {
    try {
      if (file && user && trackId) {
        const rand = Math.floor(100000 + Math.random() * 900000)
        const fileId = `${user?.uid}-${trackId}-${file.name}-${rand}`

        // upload file to storage bucket
        const storageRef = ref(storage, `audio/${fileId}`)
        await uploadBytes(storageRef, file)

        const version = await axios.post(`${BASE_URL}/versions`, {
          name: versionName,
          trackID: trackId,
          fileID: fileId
        })

        if (trackId === currentTrackId) {
          const unsortedVersions = versions? [...versions, version.data] : [version.data]
          setVersionsAndSort(unsortedVersions)
          setCurrentVersionId(version.data.id)
        }

        amplitude.track('Version Created', {
          trackName: versionName,
          trackID: trackId,
          userID: user?.uid
        })

        return true
      } else {
        console.error('Error creating version:', 'No file or user')
        return false
      }
    } catch (error) {
      console.error('Error creating version:', error)
      return false
    }
  }

  useEffect(() => {
    if (currentVersion && currentVersion.fileID) {
      const pathReference = ref(storage, `audio/${currentVersion.fileID}`)
      getDownloadURL(pathReference)
        .then((url) => {
          setCurrentAudioUrl(url)
        })
        .catch((error) => {
          console.error('Error fetching audio:', error)
        })
    }
  }, [currentVersion, versions])

  const markFinalVersion = async (versionId: string) => {
    if (versions) {
      const currentFinalVersion = versions?.find((v) => v.isFinal)
      if (currentFinalVersion) {
        await axios.put(`${BASE_URL}/versions/${currentFinalVersion.id}`, {
          isFinal: false
        })
      }

      return axios.put(`${BASE_URL}/versions/${versionId}`, {
        isFinal: true
      }).then(() => {
        amplitude.track('Final Version Unmarked', {
          versionID: versionId,
          projectID: currentTrack?.projectID,
          userID: user?.uid
        })
        const unsortedVersions = versions.map((version) =>
          version.id === versionId ? { ...version, isFinal: true } : { ...version, isFinal: false }
        )
        setVersionsAndSort(unsortedVersions)
        return true
      }).catch((error) => {
        console.error('Error marking final version:', error)
        return false
      })
    }
    
    console.error('Error marking final version:', 'No versions')
    return false
  }

  const unmarkFinalVersion = async (versionId: string) => {
    if (versions) {

      return axios.put(`${BASE_URL}/versions/${versionId}`, {
        isFinal: false
      }).then (() => {
        amplitude.track('Final Version Unmarked', {
          versionID: versionId,
          projectID: currentTrack?.projectID,
          userID: user?.uid
        })

        const unsortedVersions = versions.map((version) =>
          version.id === versionId ? { ...version, isFinal: false } : version
        )
        setVersionsAndSort(unsortedVersions)

        return true
      }).catch((error) => {
        console.error('Error marking final version:', error)
        return false
      })
    }

    console.error('Error marking final version:', 'No versions')
    return false
  }

  const renameVersion = async (versionId: string, newVersionName: string) => {

    if(versions) {
      return axios.put(`${BASE_URL}/versions/${versionId}`, {
        name: newVersionName
      }).then(() => {
        amplitude.track('Version Renamed', {
          versionID: versionId,
          newName: newVersionName,
          userID: user?.uid
        })

        const unsortedVersions = versions.map((version) =>
          version.id === versionId ? { ...version, name: newVersionName } : version
        )
        setVersionsAndSort(unsortedVersions)
        return true
      }).catch((error) => {
        console.error('Error renaming version:', error)
        return false
      })
    }

    console.error('Error renaming version:', 'No versions')
    return false
  }

  const deleteVersion = async (versionId: string) => {
    return axios.delete(`${BASE_URL}/versions/${versionId}`)
    .then((response) => {
      const unsortedVersions = versions?.filter((version) => version.id !== versionId)
      if (unsortedVersions) {
        setVersionsAndSort(unsortedVersions)
      }

      if (response.data["trackDeleted"] === "true") {
        
        setTracks((prevTracks) =>
          prevTracks ? prevTracks.filter((track) => track.id !== currentTrackId) : null
        )
        toast({
          title: 'Track deleted',
          description: 'Since the only version was deleted, the track has also been deleted.',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        navigate('/projects/'+currentProject?.id)
      }

      return true
    }).catch((error) => {
      console.error('Error deleting version:', error)
      return false
    })
  }

  useEffect(() => {
    const getCurrentVersion = () => {
      if (currentVersionId == null) {
        return null;
      }

      const version = versions?.find((version) => version.id === currentVersionId);
      if (version == undefined) {
        return null;
      }
      return version

    }

    setCurrentVersion(getCurrentVersion())
  } , [currentVersionId, versions])

  useEffect(() => {
    // Check if versions has the id of the current version
    if (versions && versions.length > 0) {
      if (!versions.some((version) => version.id === currentVersionId)) {
        const finalVersion = versions.find((version) => version.isFinal);
        if (finalVersion) {
          setCurrentVersionId(finalVersion.id);
        } else {
          setCurrentVersionId(versions[0].id);
        }
      }
    }
  }, [versions])

  // ------------------------------------------------------------------------------------------------------------------
  // Threads Tools

  const toggleThreadResolved = async (threadId: string) => {
    setThreads((prevThreads) =>
      prevThreads ? prevThreads.map((thread) =>
        thread.id === currentThread?.id ? { ...thread, isResolved: !thread.isResolved } : thread
      ) : null
    )

    const currentThread = threads?.find((thread) => thread.id === threadId)
    return axios.put(`${BASE_URL}/threads/${currentThread?.id}`, {
      isResolved: !currentThread?.isResolved
    }).then(() => {
      amplitude.track('Thread Resolved Toggled', {
        threadID: threadId,
        userID: user?.uid || 'Invitee'
      });

      return true
    }).catch((error) => {
      console.error('Error toggling thread resolved:', error)
      return false
    })
  }

  const createThread = (currentThreadOutline: Thread, messageBody: Comment[], region: Region) => { 
    currentThreadOutline.messages = messageBody
    currentThreadOutline.region = region
    currentThreadOutline.versionID = currentVersionId || ''
    currentThreadOutline.isResolved = false

    return axios.post(`${BASE_URL}/threads`, {
      messages: messageBody,
      versionID: currentVersionId,
      isResolved: false,
      region: region
    }).then((response) => {
      currentThreadOutline.id = response.data.id
      setThreads((prevThreads) =>
        prevThreads ? [...prevThreads, currentThreadOutline] : [currentThreadOutline]
      )
      amplitude.track('Thread Created', {
        versionID: currentVersionId,
        userID: user?.uid || 'Invitee'
      });

      return true
    }).catch((error) => {
      console.error('Error creating thread:', error)
      return false
    })
  }

  const updateThread = async (threadToUpdate: Thread, newMessages: Comment[]) => {

    threadToUpdate.messages = threadToUpdate.messages.concat(newMessages)
    setThreads((prevThreads) =>
      prevThreads ? prevThreads.map((thread) =>
        thread.id === threadToUpdate.id ? { ...thread, messages: threadToUpdate.messages } : thread
      ) : null
    )

    return axios.put(`${BASE_URL}/threads/${threadToUpdate.id}`, {messages: threadToUpdate?.messages, isResolved: false}).then(() => {
      amplitude.track('Thread Updated', {
        threadID: threadToUpdate.id,
        userID: user?.uid || 'Anonymous'
      });
      return true
    }).catch((error) => {
      console.error('Error updating thread:', error)
      return false
    })
  }

  const handleNewMessage = (threadId: string, newMessage: Comment) => {
    const currentThread = threads?.find((thread) => thread.id === threadId)
    if (currentThread) {
      setThreads((prevThreads) =>
        prevThreads ? prevThreads.map((thread) =>
          thread.id === currentThread.id ? { ...thread, messages: [...(thread.messages || []), newMessage] } : thread
        ) : null
      )
      return true
    } else {
      console.error('Error creating message:', 'No current thread')
      return false
    }
  }
  
  const deleteThread = async (threadId: string) => {
    setThreads((prevThreads) =>
      prevThreads ? prevThreads.filter((thread) => thread.id !== threadId) : null
    )

    return axios.delete(`${BASE_URL}/threads/${threadId}`)
    .then(() => {
      amplitude.track('Thread Deleted', {
        threadID: threadId,
        userID: user?.uid || 'Anonymous'
      });
      return true
    })
    .catch((error) => {
      console.error('Error deleting thread:', error)
      return false
    })
  }

  // ------------------------------------------------------------------------------------------------------------------
  // Effects
  const activeLoadingCountRef = useRef(0);
  const setLoadingState = (isLoading: boolean) => {
    if (isLoading) {
      activeLoadingCountRef.current += 1;
    } else {
      activeLoadingCountRef.current -= 1;
    }
    setLoadingData(activeLoadingCountRef.current > 0);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      if (!user?.uid) return;
  
      // TODO: Check if this is necessary
      socket.emit("join_notifications", user?.uid)
  
      setLoadingState(true);
      setLoadingError(null);
      try {
        const response = await axios.get(`${BASE_URL}/projects?userID=${user.uid}`);
        setProjects(response.data);
      } catch (err) {
        setLoadingError(err as Error);
      } finally {
        setLoadingState(false);
      }
    };

    fetchProjects();
  }, [user?.uid]);

  useEffect(() => {
    const updateCurrentProject = async () => {
      setLoadingState(true);
      setLoadingError(null);

      const pathParts = location.pathname.split('/').filter(Boolean)
      let projectId = ''
      let trackId = ''
  
      if (pathParts[0] === 'projects') {
        if (pathParts.length >= 2) {
          projectId = pathParts[1];
          
          setCurrentProjectId(projectId);

          if (pathParts.length >= 3) {
            trackId = pathParts[2];
            setCurrentTrackId(trackId);
          }
        }
      }

      setLoadingState(false)
    }

    const checkProjectSharingConstraints = async () => {
      if (!currentProjectId || !user) return;
    
      try {
        // Fetch project details if not exist
        let project = currentProject

        if (!project) {
          const projectResponse = await axios.get(`${BASE_URL}/projects/${currentProjectId}`);
          project = projectResponse.data;
        }
    
        // Check if the user is already part of the project
        if (project && !project.userIDs.includes(user.uid)) {
          // Use `addUserToProject` to handle adding the user and updating the collection
          await addUserToProject(user.uid, currentProjectId);
        }
      } catch (error: any) {
        if (error.status === 404) {
          console.error("Project doesn't exist")
          return
        }
        console.error('Error checking project sharing constraints:', error);
        toast({
          title: 'Error',
          description: 'An error occurred while processing your request. Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    updateCurrentProject()
    checkProjectSharingConstraints()
  }, [location, user?.uid])

  const activeProjectIdRef = useRef<string | null>(currentProjectId);
  useEffect(() => {
    activeProjectIdRef.current = currentProjectId;
    const updateTracks = async () => {
      if (!currentProjectId) return;
      setLoadingState(true);
      setLoadingError(null);

      try {
        const response = await axios.get(`${BASE_URL}/tracks?projectID=${currentProjectId}`);
        if (activeProjectIdRef.current === currentProjectId) {
          setTracks(response.data);
        }
      } catch (error) {
        setLoadingError(error as Error);
        console.error('Error fetching tracks:', error);
      } finally {
        setLoadingState(false);
      }
    };

    updateTracks();
  }, [currentProjectId]);

  const activeTrackIdRef = useRef<string | null>(currentTrackId);
  useEffect(() => {
    activeTrackIdRef.current = currentTrackId;
    const updateVersions = async () => {
      if (!currentTrackId) return;
      setLoadingState(true);
      setLoadingError(null);

      try {
        const response = await axios.get(`${BASE_URL}/versions?trackID=${currentTrackId}`);
        if (activeTrackIdRef.current === currentTrackId) {
          setVersionsAndSort(response.data);
        }
      } catch (error) {
        setLoadingError(error as Error);
        console.error('Error fetching versions:', error);
      } finally {
        setLoadingState(false);
      }
    };

    updateVersions();
  }, [currentTrackId]);

  const activeVersionIdRef = useRef<string | null>(currentVersionId);
  useEffect(() => {
    activeVersionIdRef.current = currentVersionId;
    const updateThreads = async () => {
      if (!currentVersionId) return;
      setLoadingState(true);
      setLoadingError(null);

      try {
        const response = await axios.get(`${BASE_URL}/threads?versionID=${currentVersionId}`);
        if (activeVersionIdRef.current === currentVersionId) {
          setThreads(response.data);
        }
      } catch (error) {
        setLoadingError(error as Error);
        console.error('Error fetching threads:', error);
      } finally {
        setLoadingState(false);
      }
    };

    updateThreads();
  }, [currentVersionId]); 

  return (
    <ProjectContext.Provider value={{ projects, tracks, versions, threads,
      currentProject, currentTrack, currentVersion, currentAudioUrl,
      loadingData, loadingError, 
      setCurrentProjectId, setCurrentTrackId, setCurrentVersionId,
      createProject, updateProject, deleteProject, renameProject, refetchProject, completeProject, activateProject, saveProject,
      createTrack, deleteTrack, renameTrack, sendComment, handleNewComment,
      createVersion, markFinalVersion, unmarkFinalVersion, renameVersion, deleteVersion,
      toggleThreadResolved, createThread, updateThread, handleNewMessage, deleteThread}}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjectContext must be used within a ProjectProvider');
  }
  return context;
};
