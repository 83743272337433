// https://wavesurfer.xyz/ saved my life

/* eslint-disable react/prop-types */

// TODO: Why is the file loading being weird? When I switch versions, the waveform doesn't update the file

import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../firebase'
import PropTypes from 'prop-types'
import { useWavesurfer } from '@wavesurfer/react'
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'
import Minimap from 'wavesurfer.js/dist/plugins/minimap.esm.js'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  Text,
  useToast,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Tooltip,
  Icon,
} from '@chakra-ui/react'
import { FaPauseCircle, FaPlayCircle, FaVolumeDown, FaVolumeMute, FaDownload } from 'react-icons/fa'
import useAxios from 'axios-hooks'
import { FaSquare } from 'react-icons/fa'

import ThreadModal from './ThreadModal'
import ThreadCarousel from './ThreadCarousel'
import { BASE_URL } from '../config'
import ErrorScreen from './common/ErrorScreen'
import LoadingScreen from './common/LoadingScreen'


const formatTime = (seconds) =>
  [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')

const formatTimelineTime = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes > 0 ? minutes : ''}:${
    remainingSeconds > 9 ? remainingSeconds : '0' + remainingSeconds
  }`
}

const WaveformContent = ({
  audio,
  versionID,
  threads,
  refetchThreads,
  isOwner
}) => {
  const containerRef = useRef(null)
  const toast = useToast()
  const [activeRegion, setActiveRegion] = useState(null)
  const [totalDuration, setTotalDuration] = useState(0)
  const timeInterval = 0.5
  const primaryLabelInterval = 1
  const zoomLevel = 100
  const [volumeLevel, setVolumeLevel] = useState(1)
  const [isMute, setIsMute] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [user] = useAuthState(auth)
  const [isWavesurferReady, setisWavesurferReady] = useState(false)

  const topTimeline = TimelinePlugin.create({
    height: 8,
    insertPosition: 'beforebegin',
    timeInterval: timeInterval,
    primaryLabelInterval: primaryLabelInterval,
    formatTimeCallback: (seconds) => formatTimelineTime(seconds),
    style: {
      fontSize: '14px',
      color: '#2D5B88',
      paddingBottom: '12px'
    }
  })

  const hover = Hover.create({
    lineColor: '#ff0000',
    lineWidth: 2,
    labelBackground: '#555',
    labelColor: '#fff',
    labelSize: '11px'
  })

  const regionsPlugin = RegionsPlugin.create({})

  const minimap = Minimap.create({
    height: 20,
    waveColor: '#ddd',
    progressColor: '#383351',
    normalize: true,
    responsive: true
  })

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    responsive: true,
    barHeight: 5,
    cursorWidth: 2,
    height: window.innerHeight * 0.2,
    hideScrollbar: true,
    color: 'gray',
    normalize: true,
    waveColor: '#F6AD55',
    progressColor: '#F6AD55',
    minPxPerSec: zoomLevel,
    cursorColor: '#ff0000',
    url: audio,
    plugins: useMemo(() => [hover, regionsPlugin, minimap, topTimeline], [])
  })

  const onPlayPause = useCallback(() => {
    wavesurfer && wavesurfer.playPause()
  }, [wavesurfer])

  const onVolumeChange = (value) => {
    if (value === 0) {
      setIsMute(true)
      setVolumeLevel(0)
      wavesurfer.setVolume(0)
    } else {
      setIsMute(false)
      setVolumeLevel(value)
      wavesurfer.setVolume(value)
    }
  }
  
  const onVolumeMute = () => {
    if (isMute) {
      setIsMute(false)
      setVolumeLevel(volumeLevel === 0 ? 1 : volumeLevel)
      wavesurfer.setVolume(volumeLevel === 0 ? 1 : volumeLevel)
    } else {
      setIsMute(true)
      wavesurfer.setVolume(0)
    }
  }

  const deleteRegion = (e, region) => {
    e.stopPropagation() // Prevent region click event

    const associatedThread = threads.find((thread) => {
      if (!thread.region) return false

      return thread.region.start === region.start && thread.region.end === region.end
    })


    if (associatedThread) {
      // Remove the region from the database
      if (window.confirm('Are you sure you want to delete this region?')) {
        fetch(`${BASE_URL}/threads/${associatedThread.id}`, {
          method: 'DELETE'
        })
        region.remove()

        toast({
          title: 'Region Deleted',
          description: 'The region has been successfully deleted.',
          status: 'success',
          duration: 3000
        })
        window.location.reload()
      }
    } else {
      region.remove()
    }
  }

  // Prevent right-click on the waveform
  // useEffect(() => {
  //   const handleContextmenu = (e) => {
  //     e.preventDefault()
  //   }
  //   document.addEventListener('contextmenu', handleContextmenu)
  //   return function cleanup() {
  //     document.removeEventListener('contextmenu', handleContextmenu)
  //   }
  // }, [])

  // reset the active region when the version changes
  useEffect(() => {
    setActiveRegion(null)
  }, [versionID])

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('ready', () => {
        setTotalDuration(wavesurfer.getDuration())
        setisWavesurferReady(true)
      })
    }
  }, [wavesurfer])

  useEffect(() => {
    if (wavesurfer) {
      const wsRegions = wavesurfer.registerPlugin(RegionsPlugin.create())

      wsRegions.enableDragSelection({
        color: 'rgba(0, 0, 255, 0.2)'
      })

      wsRegions.on('region-created', (region) => {
        setActiveRegion(region)
        // Add delete button to the region
        const deleteButton = document.createElement('button')
        deleteButton.innerHTML = '✖'
        deleteButton.style.cssText = `
          position: absolute;
          right: 2px;
          top: 2px;
          background: red;
          color: white;
          border: none;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          font-size: 18px;
          cursor: pointer;
          display: none;
        `
        region.element.appendChild(deleteButton)

        // Show delete button on hover
        region.element.addEventListener('mouseenter', () => {
          deleteButton.style.display = 'block'
        })

        region.element.addEventListener('mouseleave', () => {
          deleteButton.style.display = 'none'
        })

        // Delete region when button is clicked
        deleteButton.addEventListener('click', (e) => deleteRegion(e, region))

        if (threads.find((thread) => thread.region.id === region.id)) {
          onOpen()
        }
      })

      wavesurfer.on('ready', () => {
        threads.forEach((thread) => {
          const { region, isResolved } = thread
          if (region) {
            const newRegion = wsRegions.addRegion({
              start: region.start,
              end: region.end,
              drag: false,
              resize: false,
              color: isResolved ? 'rgba(180, 255, 180, 0.5)' : 'rgba(255, 0, 0, 0.3)'
            })

            // Add delete button to the region
            const deleteButton = document.createElement('button')
            deleteButton.innerHTML = '✖'
            deleteButton.style.cssText = `
              position: absolute;
              right: 2px;
              top: 2px;
              background: red;
              color: white;
              border: none;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              font-size: 18px;
              cursor: pointer;
              display: none;
            `
            newRegion.element.appendChild(deleteButton)

            // Show delete button on hover
            newRegion.element.addEventListener('mouseenter', () => {
              deleteButton.style.display = 'block'
            })

            newRegion.element.addEventListener('mouseleave', () => {
              deleteButton.style.display = 'none'
            })

            // Delete region when button is clicked
            deleteButton.addEventListener('click', (e) => deleteRegion(e, newRegion))
          }
        })
      })

      wsRegions.on('region-in', (region) => {
        setActiveRegion(region)
      })

      wsRegions.on('region-clicked', (region) => {
        setActiveRegion(region)
        wavesurfer.seekTo(region.start / wavesurfer.getDuration())
        onOpen()
      })

      wsRegions.on('region-double-clicked', (region) => {
        setActiveRegion(region)
        onOpen()
      })

      // Reset the active region when the user clicks anywhere in the waveform
      wavesurfer.on('click', () => {
        if (activeRegion !== null) {
          const associatedThread = threads.find((thread) => {
            if (!thread.region) return false
            return (
              thread.region.start === activeRegion.start &&
              thread.region.end === activeRegion.end
            )
          })

          if (!associatedThread) {
            activeRegion.remove()
          }
          setActiveRegion(null)
        }
      })
    }
  }, [wavesurfer, onOpen, threads])

  const handleThreadClick = useCallback(
    (region) => {
      if (wavesurfer && isWavesurferReady) {
        const duration = wavesurfer.getDuration()
        wavesurfer.seekTo(region.start / duration)
        setActiveRegion(region)
      }
    },
    [wavesurfer, isWavesurferReady]
  )

    const CommentLegend = () => {
    return (
      <Flex align='flex-end' ml='auto' bg='blue.50' p={2} borderRadius='8' width='fit-content'>
        <Tooltip label='Resolved: All changes communicated and discussion complete'>
          <Flex align='center' mr={4}>
            <Icon as={FaSquare} color='green.500' mr={1} />
            <Box as='span'>{'Resolved'}</Box>
          </Flex>
        </Tooltip>
        <Tooltip label='Unresolved: Active communication ongoing'>
          <Flex align='center'>
            <Icon as={FaSquare} color='red' opacity='0.7' mr={1} />
            <Box as='span'>{'Unresolved'}</Box>
          </Flex>
        </Tooltip>
      </Flex>
    )
  }

  const handleDownloadClick = async () => {
    try {
      if (user) {
        const response = await fetch(audio);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const blob = await response.blob();

        // Get the correct MIME type and file extension
        const contentType = response.headers.get('content-type');
        let fileExtension = 'mp3';  // default extension
        if (contentType) {
          if (contentType.includes('audio/mpeg')) fileExtension = 'mp3';
          else if (contentType.includes('audio/wav')) fileExtension = 'wav';
          else if (contentType.includes('audio/ogg')) fileExtension = 'ogg';
          // Add more audio types as needed
        }

        let fileName = 'audio.' + fileExtension;
        const urlParts = audio.split('/');
        const fileNameWithParams = urlParts[urlParts.length - 1];
        const match = fileNameWithParams.match(/^([^?]+)/);
        if (match) {
          fileName = decodeURIComponent(match[1]);
          // Ensure the file has the correct extension
          if (!fileName.endsWith(`.${fileExtension}`)) {
            fileName = `${fileName}.${fileExtension}`;
          }
        }

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    
        // Show a success toast
        toast({
          title: "Download Successful",
          description: `File "${fileName}" has been downloaded.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });   
      } else {
        toast({
          title: "Download Failed",
          description: "You must be signed in to download the file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (error) {
      console.error("Download failed:", error);
      
      // Show an error toast
      toast({
        title: "Download Failed",
        description: "There was an error downloading the file. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  


  return (
    <>
      <ThreadCarousel
        threads={threads}
        refetchThreads={refetchThreads}
        handleThreadClick={handleThreadClick}
        isOwner={isOwner}
      />

      <CommentLegend/>


      <Flex direction='column' pt={2}>
        <Box bg={'gray.50'} borderRadius='lg' width='100%' pt={8} boxShadow='md'>
          <div ref={containerRef} id='waveform' style={{ padding: '0 16px' }} />
          <Flex
            align='center'
            justify='space-between'
            mt={5}
            bg='gray.100'
            p={1}
            width='100%'
          >
            <HStack spacing={4}>
              <IconButton
                icon={isPlaying ? <FaPauseCircle /> : <FaPlayCircle />}
                aria-label={isPlaying ? 'Pause' : 'Play'}
                variant='ghost'
                colorScheme='orange'
                size='md'
                borderRadius='full'
                onClick={onPlayPause}
              />
              <Text color='gray.700' fontSize='sm'>
                {formatTime(currentTime)} / {formatTime(totalDuration)}
              </Text>
            </HStack>

            <Flex align='center' flex={1} mx={4}>
              <Box flex={1} mr={4}>
                <Box h='2px' bg='gray.300' position='relative'>
                  <Box
                    h='2px'
                    bg='orange.400'
                    w={`${(currentTime / totalDuration) * 100}%`}
                  />
                </Box>
              </Box>
            </Flex>

            <HStack spacing={2}>
            <Tooltip label={isOwner ? '' : 'Sign in to download'} isDisabled={isOwner}>
              <IconButton
                icon={<FaDownload />}
                aria-label="Download"
                onClick={handleDownloadClick}
                variant='ghost'
                colorScheme='orange'
                size='sm'
                borderRadius='full'
                isDisabled={!isOwner}
              />
            </Tooltip>
              <Popover placement="top" trigger="hover">
                <PopoverTrigger>
                  <IconButton
                    icon={isMute ? <FaVolumeMute /> : <FaVolumeDown />}
                    onClick={onVolumeMute}
                    variant='ghost'
                    colorScheme='orange'
                    size='sm'
                    borderRadius='full'
                  />
                </PopoverTrigger>
                <PopoverContent width="40px">
                  <PopoverBody>
                    <Slider
                      aria-label='volume-control'
                      defaultValue={volumeLevel}
                      min={0}
                      max={1}
                      step={0.01}
                      orientation="vertical"
                      minH="100px"
                      onChange={(value) => onVolumeChange(value)}
                    >
                      <SliderTrack bg='gray.300'>
                        <SliderFilledTrack bg='orange.400' />
                      </SliderTrack>
                      <SliderThumb boxSize={3} bg='orange.500' />
                    </Slider>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
          </Flex>
        </Box>
        
      </Flex>

      <ThreadModal
        isOpen={isOpen}
        onClose={onClose}
        versionID={versionID}
        region={activeRegion}
        threadParam={
          threads &&
          threads.find((thread) => {
            if (!thread.region || !activeRegion) {
              return false
            }
            return (
              thread.region.start === activeRegion.start &&
              thread.region.end === activeRegion.end
            )
          })
        }
        filePath={audio}
        refetchThreads={refetchThreads}
        isOwner={isOwner}
      />
    </>
  )
}

WaveformContent.propTypes = {
  audio: PropTypes.string.isRequired
}

// const ThreadInfo = () => {
//   const animationKeyframes = keyframes`
//     0% { transform: scale(1) rotate(0); border-radius: 20%; }
//     11.1% { transform: scale(1.5) rotate(0); border-radius: 20%; }
//     22.2% { transform: scale(1.5) rotate(270deg); border-radius: 50%; }
//     33.3% { transform: scale(1.5) rotate(270deg); border-radius: 50%; }
//     44.4% { transform: scale(1) rotate(0); border-radius: 20%; }
//     55.5% { transform: scale(1) rotate(0); border-radius: 20%; }
//     66.6% { transform: scale(1.5) rotate(0); border-radius: 20%; }
//     77.7% { transform: scale(1.5) rotate(270deg); border-radius: 50%; }
//     88.8% { transform: scale(1.5) rotate(270deg); border-radius: 50%; }
//     100% { transform: scale(1) rotate(0); border-radius: 20%; }
//   `
//   const pulseAnimation = `${animationKeyframes} 6s ease-in-out`
//   const tooltipLabel = (
//     <Box>
//       <Text>
//         <Text as='span' fontWeight='bold' color='orange.200'>
//           Click
//         </Text>{' '}
//         and{' '}
//         <Text as='span' fontWeight='bold' color='orange.200'>
//           Drag
//         </Text>{' '}
//         on the waveform to add a thread
//       </Text>
//       <Text>
//         <Text as='span' fontWeight='bold' color='orange.200'>
//           Double Click
//         </Text>{' '}
//         to open a thread
//       </Text>
//     </Box>
//   )

//   return (
//     <Tooltip label={tooltipLabel} aria-label='Controls' hasArrow>
//       <Alert status='info' borderRadius={'5'} p='2' justifySelf='right'>
//         <AlertIcon as={motion.div} animation={pulseAnimation} />
//         <Text>
//           <code>Controls</code>
//         </Text>
//       </Alert>
//     </Tooltip>
//   )
// }

const Waveform = ({ audio, versionID, isComplete, isOwner }) => {
  const [{ data: threads, loading: threadsLoading, error }, refetchThreads] = useAxios({
    method: 'GET',
    url: `${BASE_URL}/threads?versionID=${versionID}`
  })

  if (error) {
    return <ErrorScreen error={error} />
  }

  if (threadsLoading) {
    return <LoadingScreen />
  }

  return (
    <WaveformContent
      audio={audio}
      versionID={versionID}
      threads={threads}
      isComplete={isComplete}
      refetchThreads={refetchThreads}
      isOwner={isOwner}
    />
  )
}

export default Waveform
