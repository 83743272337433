import React, { useEffect, useState } from 'react'
import {
  Box,
  VStack,
  Text,
  Flex,
  Button,
  Icon,
  Image,
  HStack,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  Spacer,
  Progress,
} from '@chakra-ui/react'
import {
  FaFolder,
  FaPlus,
  FaBell,
  FaChevronLeft,
  FaChevronRight,
  FaSignOutAlt,
  FaUser,
  FaSignInAlt,
  FaPlay,
  FaExclamationTriangle,
  FaCrown,
  FaCreditCard,
} from 'react-icons/fa'
import CreateProject from '../project/CreateProject'
import { Project, Track } from '../../_helpers/types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import FeedbackButton from './FeedbackButton'
import { signOut } from 'firebase/auth'
import NotificationsModal from './NotificationsModal'
import CreateTrack from '../project/CreateTrack'
import { useNotification } from './NotificationContext'
import { useUser } from './UserContext'
import UpgradeModal from './UpgradeModal'
import { SharedBadge, UpgradeBadge } from './ProjectBadge'
import { isProjectShared } from '../project/ProjectUtils'
import { useProjectContext } from '../project/ProjectContext'
import SignInModal from '../auth/SignInModal'

interface SidebarProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  showSidebar: boolean
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onOpen, onClose, showSidebar }) => {
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const {
    isOpen: isCreateProjectOpen,
    onOpen: onCreateProjectOpen,
    onClose: onCreateProjectClose
  } = useDisclosure()
  const {
    isOpen: isCreateTrackOpen,
    onOpen: onCreateTrackOpen,
    onClose: onCreateTrackClose
  } = useDisclosure()
  const {
    isOpen: isNotificationsOpen,
    onOpen: onNotificationsOpen,
    onClose: onNotificationsClose
  } = useDisclosure()

  const { projects, tracks, loadingData, loadingError, refetchProject, currentProject} = useProjectContext();
  
  const [displayTracks, setDisplayTracks] = useState(false)
  const location = useLocation()

  const { unreadCount, fetchUnreadNotifications } = useNotification();  // Access notification count
  const { soloflowUser, getUserPricingPlan, getNumberOfSharedProjects, closeUpgradeModal, isUpgradeModalOpen, canUserEditSharedProject, isSignInModalOpen, closeSignInModal, openRelevantModal} = useUser(); 

  const [sharedProjectCount, setSharedProjectCount] = useState(0);
  const [sharedProjectLimit, setSharedProjectLimit] = useState(2); // Default for FREE users

  // Fetch shared project data when user changes
  useEffect(() => {
    if (soloflowUser) {
      const userPlan = getUserPricingPlan(); // e.g., 'FREE' or 'PRO'
      setSharedProjectLimit(userPlan === 'PRO' ? Infinity : soloflowUser.max_shared_project_limit);
      setSharedProjectCount(getNumberOfSharedProjects()??1)
      isNearLimit = soloflowUser?.plan === 'FREE' && sharedProjectCount >= sharedProjectLimit;
      sharedProjectsUsedPercent = (sharedProjectCount / sharedProjectLimit) * 100;
    }
  }, [soloflowUser]);

  let isNearLimit = soloflowUser?.plan === 'FREE' && sharedProjectCount >= sharedProjectLimit;
  let sharedProjectsUsedPercent = (sharedProjectCount / sharedProjectLimit) * 100;

  useEffect(() => {
    if (user) {
      // Fetch unread count initially when the component loads
      fetchUnreadNotifications(user.uid);
    }
  }, [user, currentProject])

  useEffect(() => {
    updateTracksDisplay()
  }, [location, user?.uid])

  const updateTracksDisplay = () => {
    const pathParts = location.pathname.split('/').filter(Boolean)
    if (pathParts[0] === 'projects') {
      if (pathParts.length === 3) {
        setDisplayTracks(true)
      } else if (pathParts.length < 3) {
        setDisplayTracks(false)
      }
    }
  }

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter(Boolean)
    if (pathParts[0] === 'projects') {
      let projectId: string
      if (pathParts.length === 3) {
        [, projectId] = pathParts
      } else if (pathParts.length === 2) {
        [, projectId] = pathParts
      } else {
        return
      }

      // Check if the project is already in projectsData
      const projectExists = projects.some((project) => project.id === projectId)

      if (!projectExists) {
        refetchProject(projectId)
      }
    }
  }, [])

  useEffect(() => {
    onOpen()
  }, [onOpen])

  const handleSignOut = async () => {
    try {
      await signOut(auth)
      navigate('/login')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  const handleSignIn = () => {
    navigate(`/login?returnUrl=${window.location.pathname}`)
  }

  if (!showSidebar || loadingData || loadingError) {
    return null
  }

  return (
    <>
      <Button
        position='fixed'
        top='5%'
        left={isOpen ? '300px' : '0'}
        transform='translateY(-50%)'
        zIndex='999'
        onClick={isOpen ? onClose : onOpen}
        colorScheme='orange'
        size='xs'
        borderRadius='0 4px 4px 0'
        transition='left 0.3s'
        height='30px'
      >
        <Icon as={isOpen ? FaChevronLeft : FaChevronRight} width='auto' />
      </Button>
      <Box
        position='fixed'
        left='0'
        top='0'
        bottom='0'
        width={isOpen ? '300px' : '0'}
        overflowY='auto'
        bg='orange.100'
        color='black'
        transition='width 0.3s'
        zIndex='998'
        display='flex'
        flexDirection='column'
      >
        <Box p={4} bg='orange.100'>
          {process.env.REACT_APP_NODE_ENV !== "production" && (
            <><Box
              p={1}
              bg='#DD6B20'
              textAlign="center"
              fontSize="s"
              fontWeight="bold"
              color="white">
              {process.env.REACT_APP_NODE_ENV == "testing" ? "TESTING SERVER" : "DEVELOPMENT SERVER"}
              {process.env.REACT_APP_NODE_ENV == "testing" && (
                <Text fontSize="sm">
                  Now viewing <code>{window.location.hostname.split(".")[0]}</code>
                </Text>
              )}
            </Box><br></br></>
          )}
          <Flex justify='space-between' align='center'>
            <Button onClick={() => navigate('/')} variant= 'link'>
              <Image src='/logo.svg' w='150px' />
            </Button>            
            <HStack alignItems='center'>
              <IconButton
                aria-label="Notifications"
                icon={<>
                  <Icon as={FaBell} w={5} h={5} color='orange.600' />
                  {unreadCount > 0 && (
                    <Box
                      position="absolute"
                      top="-5px"
                      right="-5px"
                      bg="red.500"
                      borderRadius="full"
                      width="18px"
                      height="18px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      fontSize="xs"
                      fontWeight="bold"
                      color="white"
                    >
                      {unreadCount}
                    </Box>
                  )}
                </>}
                onClick={onNotificationsOpen}
                variant="ghost"
              />
              <Menu>
                <MenuButton>
                  {user && user.photoURL ? (
                    <Image
                      src={user.photoURL}
                      width='32px'
                      height='32px'
                      borderRadius='full'
                    />
                  ) : (
                    <Icon as={FaUser} w={5} h={5} mr={2} color='orange.600' />
                  )}
                </MenuButton>
                {user ? (
                  <MenuList>
                    <MenuItem icon={<FaUser color='orange' />} isDisabled>
                      {user.email}
                    </MenuItem>
                    <MenuItem icon={<FaCreditCard color='orange' />} onClick={() => navigate('/pricing')}>
                      {soloflowUser?.plan == "PRO" ? "Manage Subscription" : "Upgrade to Pro"}
                    </MenuItem>
                    <MenuItem icon={<FaSignOutAlt color='red' />} onClick={handleSignOut}>
                      Sign Out
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList>
                    <MenuItem icon={<FaSignInAlt color='green' />} onClick={handleSignIn}>
                      Sign In
                    </MenuItem>
                  </MenuList>
                )}
              </Menu>
            </HStack>
          </Flex>
        </Box>

        <VStack align='stretch' spacing={4} p={4}>
        {soloflowUser && soloflowUser.plan === 'FREE' && (
        <Tooltip label="You've reached your shared project limit! Upgrade to Pro for unlimited shared projects." isDisabled={!isNearLimit}>
        <Box p={4} bg='yellow.50' borderRadius='md' mb={2}>
          <Flex justify='space-between' align='center'>
            <Text fontSize='sm' fontWeight='bold' color='orange.600'>
              Shared Projects Used: {sharedProjectCount} / {sharedProjectLimit}
            </Text>
            {isNearLimit && (
                <Icon as={FaExclamationTriangle} color='red.500' />
            )}
          </Flex>
          <Progress
            value={sharedProjectsUsedPercent}
            size='sm'
            colorScheme={isNearLimit ? 'red' : 'orange'}
            mt={2}
          />
            <Button
              size='sm'
              mt={2}
              colorScheme='orange'
              onClick={(e) => {
                e.stopPropagation();
                // openUpgradeModal();
                navigate('/pricing?checkout=true')
              }}
              width='full'
            >
              {soloflowUser.stripe_customer_id ? 'Upgrade to Pro' : 'Start Free Trial'}
            </Button>
        </Box>
        </Tooltip>
      )}

      {soloflowUser && soloflowUser.plan === "PRO" && (
        <Tooltip label="Pro users enjoy unlimited shared projects and collaboration features">
          <Box p={4} bg='green.100' borderRadius='md' mb={2}>
            <Flex justify='space-between' align='center'>
              <Text fontSize='sm' color='green.600'>
                You are using <strong>Soloflow Pro</strong>
              </Text>
              <Icon as={FaCrown} color='yellow.500' />
            </Flex>
          </Box>
        </Tooltip>
      )}

          {user ? (
            <Button
            variant='ghost'
            justifyContent='flex-start'
            leftIcon={<Icon as={FaFolder} />}
            color='black'
            onClick={() => navigate('/projects')}
          >
            Library
          </Button>
          ): (
            <></>
          )}
          
            {displayTracks ? (
              <Text fontWeight='bold'>Tracks</Text>
            ): (
              <Text fontWeight='bold'>Projects</Text>
            )}

          {displayTracks ? (
            tracks?.sort((a: Track, b: Track) => {
              const dateA = new Date(a.createdAt)
              const dateB = new Date(b.createdAt)
              return dateA.getTime() - dateB.getTime()
            }).map((track:Track) => (
              <Button
                  key={track.id}
                  bg='orange.100'
                  color='black'
                  justifyContent='flex-start'
                  leftIcon={<Icon as={FaPlay} />}
                  _hover={{ bg: 'orange.200' }}
                  onClick={() => navigate(`/projects/${track.projectID}/${track.id}`)}
                >
                  <Text whiteSpace='nowrap' textOverflow='ellipsis' overflowX='hidden'>
                    {track.name}
                  </Text>
                </Button>
            ))
          ) : (
            projects
              ?.sort((a: Project, b: Project) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
                return dateA.getTime() - dateB.getTime()
              })
              .reverse()
              .map((project?: Project) => {
                if (!project) return
                
                // Check if the project is shared
                const isShared = isProjectShared(project);

                // Check if the user can edit the shared project
                const canEdit = canUserEditSharedProject(project);

                return (
                  <Button
                    key={project.id}
                    bg="orange.100"
                    color="black"
                    justifyContent="flex-start"
                    leftIcon={<Icon as={FaFolder} />}
                    _hover={{ bg: "orange.200" }}
                    onClick={() => navigate(`/projects/${project.id}`)}
                  >
                    <Text whiteSpace="nowrap" textOverflow="ellipsis" overflowX="hidden">
                      {project.name}
                    </Text>
                    <Spacer />
                    {!canEdit && user && <UpgradeBadge />}
                    {isShared && <SharedBadge />}
                  </Button>
                );
              })
          )}

          <Tooltip label={user ? '' : 'Sign in to access this'} isDisabled={user?true:false}>
          <Button
            variant='ghost'
            justifyContent='flex-start'
            leftIcon={<Icon as={FaPlus} />}
            onClick={(e) => {
              e.stopPropagation();
              if (displayTracks && !canUserEditSharedProject(currentProject)) {
                openRelevantModal();
                return;
              }
              displayTracks ? onCreateTrackOpen() : onCreateProjectOpen()
            }}
            color='black'
            isDisabled={!user}
          >
            {(displayTracks ? "New Track" : "New Project")}
          </Button>
          </Tooltip>

        </VStack>

        <Box width={'fit-content'} px={4} mt='auto'>
          <FeedbackButton />
        </Box>
      </Box>
      <CreateProject isOpen={isCreateProjectOpen} onClose={onCreateProjectClose} />
      { currentProject && 
      <CreateTrack isOpen={isCreateTrackOpen} onClose={onCreateTrackClose} />
      }
      <NotificationsModal
        isOpen={isNotificationsOpen}
        onClose={onNotificationsClose}
      />
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={closeUpgradeModal}
      />     
      <SignInModal
        isOpen={isSignInModalOpen}
        onClose={closeSignInModal} 
      /> 
    </>
  )
}

export default Sidebar