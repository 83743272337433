import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  VStack,
  Text,
  Box,
  Spinner,
  Flex,
  useToast,
  Tooltip,
  Icon,
  IconButton
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { ViewIcon, ChatIcon } from '@chakra-ui/icons'
import { BASE_URL } from '../../config'
import axios from 'axios'
import { useNotification } from './NotificationContext'
import { Notification } from '../../_helpers/types'
import GoogleProvider from '../auth/GoogleProvider'

interface NotificationsModalProps {
  isOpen: boolean
  onClose: () => void
}

const NotificationsModal: React.FC<NotificationsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate()
  const [, setActiveTab] = useState<'unread' | 'all'>('unread')

  const { notifications, setNotifications, unreadCount, setUnreadCount, unreadNotifications, setUnreadNotifications } = useNotification();

  const [user] = useAuthState(auth)
  const [loading, setLoading] = useState(false)
  const toast = useToast()


  React.useEffect(() => {
    const fetchNotifications = async () => {
      if (user) {
        try {
          setLoading(true);
          const response = await axios.get(`${BASE_URL}/notifications/${user.uid}`);
          setNotifications(response.data);
        } catch (error) {
          console.error('Error fetching notifications:', error);
        } finally {
          setLoading(false);
        }
      }
    }

    fetchNotifications();
  }, [])

  const formatTimeAgo = (date: Date | string | number) => {
    const now = new Date().getTime();
    const targetDate = new Date(date).getTime(); // Convert to milliseconds
    const diff = Math.floor((now - targetDate) / 1000); // Difference in seconds
  
    if (diff < 60) return `${diff} seconds ago`;
    if (diff < 3600) return `${Math.floor(diff / 60)} minutes ago`;
    if (diff < 86400) return `${Math.floor(diff / 3600)} hours ago`;
    return `${Math.floor(diff / 86400)} days ago`;
  };
  
  
  const handleNotificationClick = async (notification: Notification) => {
    setLoading(true)
    try {
      // Call the backend API to resolve the notification
      const response = await axios.get(
        `${BASE_URL}/notifications/resolve/${notification.threadID}`
      );
      const { projectID, trackID, threadID } = response.data;
  
      // Navigate to the appropriate project and track page
      navigate(`/projects/${projectID}/${trackID}`, { state: { threadId: threadID } });
    } catch (error) {
      console.error('Error handling notification click:', error);
  
      // Show error toast if any part of the lookup fails
      toast({
        title: 'Error',
        description: 'Unable to resolve the notification. It may have been deleted.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      onClose();
    }
  }

  const markAsRead = async (notification: Notification) => {
    try {
      await axios.put(`${BASE_URL}/notifications/${notification.id}/read`);
      
      setNotifications((prev: Notification[]) =>
        prev.map((n) =>
          n.id === notification.id ? { ...n, read: true } : n
        )
      );
      
      setUnreadCount(prev => (prev - 1))
      
      setUnreadNotifications(prev => ({
        ...prev,
        [notification.threadID]: unreadNotifications[notification.threadID] - 1
      }))

    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };
  
  const renderNotificationList = (notificationList: Notification[]) => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <Spinner />
        </Box>
      )
    }

    return (
      <VStack spacing={4} align="stretch">
        {notificationList.length === 0 ? (
          <Text>No notifications</Text>
        ) : (
          notificationList
            .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .map((notification) => (
        <Box
          key={notification.id}
          p={4}
          borderWidth={1}
          borderRadius="md"
          bg={notification.read ? 'orange.50' : 'orange.100'}
              >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <Box maxW={'75%'}>
                    <Text fontWeight="bold" isTruncated>New Message From {notification.metaData?.senderEmail || "loading..."}</Text>
                    <Text fontSize="sm" isTruncated>Project: {notification.metaData?.projectName || "loading..."}</Text>
                    <Text fontSize="sm" isTruncated>Track: {notification.metaData?.trackName || "loading..."}</Text>
                </Box>
                <Flex>
                    {!notification.read && (
                    <Tooltip label="Mark as read" hasArrow>
                      <IconButton
                      aria-label="Mark as read"
                      icon={<Icon as={ViewIcon} />}
                      size="sm"
                      colorScheme="blue"
                      mr={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        markAsRead(notification);
                      }}
                      />
                    </Tooltip>
                    )}
                  <Tooltip label="View comment" hasArrow>
                    <IconButton
                      aria-label="View comment"
                      icon={<Icon as={ChatIcon} />}
                      size="sm"
                      colorScheme="orange"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNotificationClick(notification);
                      }}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
              <Text fontSize="sm" color="gray.500">
                {formatTimeAgo(notification.createdAt)}
              </Text>
            </Box>
          ))
        )}
      </VStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Notifications</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {user ? (
            <Tabs isFitted variant="enclosed" onChange={(index) => setActiveTab(index === 0 ? 'unread' : 'all')}>
              <TabList mb="1em">
                <Tab>Unread ({unreadCount})</Tab>
                <Tab>All</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {renderNotificationList(notifications.filter(n => !n.read))}
                </TabPanel>
                <TabPanel>
                  {renderNotificationList(notifications)}
                </TabPanel>
              </TabPanels>
            </Tabs>
          ): (
            <VStack spacing={4} align="stretch">
              <GoogleProvider shouldSave={false}></GoogleProvider>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NotificationsModal