import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  HStack,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  extendTheme,
  Flex,
  Badge,
  Container,
  Heading,
  Image,
  Grid,
  GridItem,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Textarea,
  useToast,
  useDisclosure,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  Spinner,
  Spacer
} from '@chakra-ui/react';
import { FaUser, FaSignOutAlt, FaCreditCard } from 'react-icons/fa'
import { CheckIcon, InfoIcon } from '@chakra-ui/icons';
import { FaUserFriends, FaEdit, FaLock, FaUnlock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import Footer from '../common/Footer';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import axios from 'axios';
import { BASE_URL } from '../../config';
import { loadStripe } from '@stripe/stripe-js';
import { useUser } from '../common/UserContext';
import ActionConfirmation from '../common/ActionConfirmation';

// Theme definition remains the same
const themeColors = {
  brand: {
    50: '#FFF5E6',
    100: '#FFE0B2',
    200: '#FFCC80',
    300: '#FFB74D',
    400: '#FFA726',
    500: '#FF9800',
    600: '#FB8C00',
    700: '#F57C00',
    800: '#EF6C00',
    900: '#E65100'
  }
}

const theme = extendTheme({
  colors: themeColors,
  fonts: {
    heading: '"Poppins", sans-serif',
    body: '"Inter", sans-serif'
  }
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string || '', {});

interface PricingPageProps {
  setShowSidebar: (showSidebar: boolean) => void
}

const PricingPage: React.FC<PricingPageProps> = ({ setShowSidebar }) => {
  const navigate = useNavigate();
  const boxBgColor = useColorModeValue('white', 'gray.700');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const [user] = useAuthState(auth)

  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id'); 
  const readyToCheckout = queryParams.get('checkout');

  const [isLoading, setIsLoading] = React.useState(false);

  const { upgradeToPro , downgradeToFree, soloflowUser } = useUser();
  const [isSubscribed, setIsSubscribed] = React.useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = React.useState<string | null>(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const {
      isOpen: isCancelSubscriptionConfirmationOpen,
      onOpen: openCancelSubscriptionConfirmation,
      onClose: closeCancelSubscriptionConfirmation
    } = useDisclosure()

  React.useEffect(() => {
      setShowSidebar(false)
  }, [])

  const [showCheckout, setShowCheckout] = useState(readyToCheckout || false);

  React.useEffect(() => {
    if (soloflowUser) {
      if (soloflowUser.stripe_customer_id) {
        setSubscriptionInfo(null);
        axios.get(`${BASE_URL}/subscriptions/trial?stripe_customer_id=${soloflowUser.stripe_customer_id}`)
        .then((response) => {
          if (response.data.active_free_trials && response.data.active_free_trials.length > 0) {
            const trialEndDate = new Date(response.data.active_free_trials[0].trial_end).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
            setSubscriptionInfo('You have an active free trial that ends on ' + trialEndDate);
          }
        })

        axios.get(`${BASE_URL}/subscriptions/active?stripe_customer_id=${soloflowUser.stripe_customer_id}`)
        .then((response) => {
            if (response.data.active_subscriptions && response.data.active_subscriptions.length > 0) {
            const renewalDate = new Date(response.data.active_subscriptions[0].current_period_end).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
            setSubscriptionInfo('You have an active subscription that renews on ' + renewalDate);
            }
        })
      }
      setIsSubscribed(soloflowUser.plan === 'PRO');
    }
  } , [soloflowUser?.plan]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const templateParams = {
      name: formData.name,
      email: formData.email,
      message: formData.message
    };
    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID as string
    const userPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string      
    const templateID = 'template_c00snqd';

    try {
      await emailjs.send(serviceID, templateID, templateParams, userPublicKey);
      toast({
        title: 'Quote request sent successfully',
        description: "We'll get back to you soon!",
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      setFormData({ name: '', email: '', message: '' });
      onClose();
    } catch (error) {
      toast({
        title: 'Error sending quote request',
        description: "Please try again later.",
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const handleDowngrade = async () => {
    if (soloflowUser) {
      try {
        await axios.post(`${BASE_URL}/subscriptions/cancel`, {
            stripe_id: soloflowUser?.stripe_customer_id
        }).then((response) => {
            console.log(response.data);
        })
        await downgradeToFree();
        return true;
      } catch (error) {
        console.error('Error downgrading subscription:', error);
        return false;
      }
    }
    return false;
  }

  const modifyProMembership = () => {
    if (!user) {
      navigate('/login?returnUrl=/pricing');
      return;
    }

    if (isSubscribed) {
      openCancelSubscriptionConfirmation();
    } else {
      setShowCheckout(true);
    }
  }

  const [clientSecret, setClientSecret] = React.useState('');

  const fetchClientSecret = async () => {
      return clientSecret;
  }

  React.useEffect(() => {
      if (soloflowUser) {
        setClientSecret('')
        setIsLoading(true);
        axios.get(`${BASE_URL}/subscriptions/session`, {
            params: {
                email: soloflowUser.email,
                allow_trial: !soloflowUser.stripe_customer_id,
                frontend_url: window.location.origin
            }
        })
        .then((response) => {
            setClientSecret(response.data);
        })
        .catch((error) => {
            toast({
                title: 'Error fetching session.',
                description: 'Failed accessing our payment portal. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.error('Failed to fetch client secret:', error);
        })
        .finally(() => {
            setIsLoading(false);
        });
      }
  }, [soloflowUser]);

  const calledSessions = React.useRef(new Set<string>());
  React.useEffect(() => {
    if (sessionId && !calledSessions.current.has(sessionId)) {
      setIsLoading(true)
      axios
      .get(`${BASE_URL}/subscriptions/checkout_info?session_id=${sessionId}`)
      .then((response) => {
        const checkoutInfo = response.data;
        if (checkoutInfo.session.status === 'complete' && user) {
          calledSessions.current.add(sessionId);
          upgradeToPro(checkoutInfo.customer.id);
          toast({
              title: 'Subscription Successful!',
              description: `Welcome, ${checkoutInfo.customer.email}!`,
              status: 'success',
              duration: 5000,
              isClosable: true,
          });

          const url = new URL(window.location.href);
          url.searchParams.delete('session_id');
          window.history.replaceState(null, '', url.toString());
        } else if (checkoutInfo.session.status === 'open') {
            toast({
                title: 'Subscription Incomplete',
                description: 'Please complete your subscription process.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
        }
      }).catch((error) => {
            console.error('Failed to fetch session status:', error);
      }).finally(() => {
          setIsLoading(false);
      })
    }
  }, [sessionId, user]);

  const plans = [
    {
      name: "Starter",
      price: { monthly: "Free" },
      features: [
        {
          main: "Unlimited Private Projects",
        },
        {
          main: "Upto 2 Shared Projects",
          sub: [
            { 
              text: "", 
              info: ""
            },
            { 
              text: "Limited Access", 
              icon: FaEdit,
              info: "Can only edit within your shared project limit"
            },
            // { 
            //   text: "View-only Access for Shared Projects", 
            //   icon: FaEye,
            //   info: "Can still view projects shared with you beyond the limit"
            // },
            { 
              text: "Basic Collaboration Tools", 
              icon: FaLock,
              info: "Limited commenting and version control"
            },
          ]
        },
        {
          main: "Limited Data Storage"
        }
      ],
      audience: "For Aspiring Audio Creators",
      cta: "Get Started"
    },
    {
      name: "Pro",
      price: { 
        original: 10.99,
        monthly: 7.99
      },
      features: [
        {
          main: "Unlimited Private Projects",
        },
        {
          main: "Unlimited Shared Projects",
          sub: [
            // { 
            //   text: "Unlimited Shared Projects", 
            //   icon: FaUserFriends,
            //   info: "No limits on how many projects you can share"
            // },
            { 
              text: "Full Edit Access", 
              icon: FaEdit,
              info: "Edit any project shared with you"
            },
            { 
              text: "Advanced Collaboration Tools", 
              icon: FaUnlock,
              info: "Unlimited commenting, version control, and more"
            },
            { 
              text: "Priority Support", 
              icon: FaUserFriends,
              info: "Get help when you need it"
            },
          ]
        },
        {
          main: "Additional Data Storage"
        }
      ],
      audience: "For Seasoned Professionals",
      cta: soloflowUser && soloflowUser.stripe_customer_id ? "Get Pro" : "Start Free Trial" 
    },
    {
      name: "Enterprise",
      price: { monthly: "Custom" },
      features: [
        "Custom Features",
        "Custom Requirements",
        "Custom Integrations",
        "Dedicated Support"
      ],
      audience: "For Studios and Institutions",
      cta: "Request a Quote"
    }
  ];

  const handleSignOut = async () => {
    try {
      await signOut(auth)
      navigate('/login')
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  return (
    <ChakraProvider theme={theme}>
      <Box minHeight="100vh" bg={'orange.100'}>
        {/* Navbar stays the same */}
        <Box as='nav' bg='white' boxShadow='sm' position='sticky' top={0} zIndex={10}>
          <Flex mx='auto' px={4} align='center' justify='space-between' padding={1}>
            <Flex align='center'>
              <Button onClick={() => navigate('/')} variant='link'>
                  <Image src='/logo.svg' w='150px' paddingLeft={2} padding={2}/>
              </Button>
            </Flex>
            {user? (
              <Flex align="center" gap={2}> {/* Adjust gap as needed */}
                <Button colorScheme='brand' onClick={() => navigate('/projects')}>
                  Your Projects
                </Button>
                <Menu>
                  <MenuButton>
                    {user && user.photoURL ? (
                      <Image
                        src={user.photoURL}
                        width='32px'
                        height='32px'
                        borderRadius='full'
                      />
                    ) : (
                      <Icon as={FaUser} w={5} h={5} color='orange.600' />
                    )}
                  </MenuButton>
                  <MenuList>
                    <MenuItem icon={<FaUser color='orange' />} isDisabled>
                      {user.email}
                    </MenuItem>
                    <MenuItem icon={<FaCreditCard color='orange' />} onClick={() => navigate('/pricing')}>
                      {soloflowUser?.plan == "PRO" ? "Manage Subscription" : "Upgrade to Pro"}
                    </MenuItem>
                    <MenuItem icon={<FaSignOutAlt color='red' />} onClick={handleSignOut}>
                      Sign Out
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            ):(
            <HStack>
              <Button variant='ghost' onClick={() => navigate('/login?returnUrl=/pricing')}>
                Log in
              </Button>
              <Button colorScheme='brand' onClick={() => navigate('/signup')}>
                Sign up
              </Button>
            </HStack>
            )}
          </Flex>
        </Box>

        {isSubscribed ? 
          <Box bg="orange.500" color="white" p={4} textAlign="center">
            <Text fontSize="lg" fontWeight="bold">
              {subscriptionInfo || 'Thanks for your subscription! Manage your membership below.'}
            </Text>
          </Box> : null
        }

        {isLoading ? 
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="orange.500"
            size="xl"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"/>
         :
        <Container maxW="container.xl" py={10}>
        { !showCheckout ?

          <VStack spacing={12} align="center">
            <VStack spacing={4}>
              <Heading as="h1" size="2xl" textAlign="center">Choose Your Plan</Heading>
              <Text fontSize="xl" color="gray.600" textAlign="center">
                Unlock unlimited collaboration possibilities
              </Text>
            </VStack>

            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={8}>
              {plans.map((plan, index) => (
                <GridItem key={plan.name}>
                  <Box 
                    bg={boxBgColor} 
                    p={8} 
                    borderRadius="lg" 
                    boxShadow="md" 
                    height="100%" 
                    position="relative" 
                    display="flex" 
                    flexDirection="column"
                    border={index === 1 ? "2px solid" : "none"}
                    borderColor="orange.500"
                  >
                    {index === 1 && (
                      <Badge 
                        bg="orange.500" 
                        color='white' 
                        position="absolute" 
                        top="-3" 
                        right="-3" 
                        px={3} 
                        py={1} 
                        borderRadius="full"
                      >
                        Most Popular
                      </Badge>
                    )}
                    <VStack align="start" spacing={2} height="100%">
                      <HStack>
                      <Text fontSize="3xl" fontWeight="bold">{plan.name}</Text>
                      <Spacer/>
                      {index === 1 && (
                        <Badge fontSize="md" colorScheme="red" variant="solid">
                          Limited Time Offer! 
                        </Badge>
                      )}
                      </HStack>  
                      <HStack spacing={1} align="center">
                        {plan.price.original && (
                          <Text 
                            fontSize="xl" 
                            color="gray.600" 
                            textDecoration="line-through"
                            mr={2}
                          >
                            ${plan.price.original}
                          </Text>
                        )}
                        <Text fontSize="2xl" fontWeight="bold">
                          {typeof plan.price.monthly === 'number' ? 
                            `$${plan.price.monthly}` : 
                            plan.price.monthly}
                        </Text>
                        {typeof plan.price.monthly === 'number' && (
                          <Text fontSize="md" color="gray.500">/month</Text>
                        )}
                      </HStack>
                      <Box 
                        borderColor="orange.500" 
                        borderWidth={2} 
                        color="orange.500" 
                        p={2} 
                        borderRadius="md" 
                        w="full" 
                        textAlign="center" 
                        fontWeight={'medium'} 
                        fontSize={'lg'} 
                        mt={2}
                      >
                        {plan.audience}
                      </Box>

                      <List spacing={4} flex={1} mt={4}>
                        {plan.features.map((feature, i) => (
                          <ListItem key={i}>
                            {typeof feature === 'string' ? (
                              <HStack>
                                <ListIcon as={CheckIcon} color="green.500" />
                                <Text>{feature}</Text>
                              </HStack>
                            ) : (
                              <VStack align="start" spacing={2}>
                                <HStack>
                                  <ListIcon as={CheckIcon} color="green.500" />
                                  <Text fontWeight="medium">{feature.main}</Text>
                                </HStack>
                                {feature.sub && (
                                <List spacing={2} pl={8}>
                                  {feature.sub.map((subFeature, j) => (
                                    <ListItem key={j} display="flex" alignItems="center">
                                      {subFeature.icon && <ListIcon 
                                        as={subFeature.icon} 
                                        color={index === 0 && subFeature.icon === FaLock ? 
                                          "red.500" : "orange.500"} 
                                      /> }
                                      <Text fontSize="sm">{subFeature.text}</Text>
                                      {subFeature.info && (
                                        <Tooltip label={subFeature.info} placement="top">
                                          <InfoIcon ml={1} color="orange.500" boxSize={3} />
                                        </Tooltip>
                                      )}
                                    </ListItem>
                                  ))}
                                </List>
                                )}
                              </VStack>
                            )}
                          </ListItem>
                        ))}
                      </List>

                      {index === 0 && (
                        <Text fontSize="sm" color="gray.500" mt={4}>
                          *No credit card required
                        </Text>
                      )}

                      {index === 1 && !isSubscribed && !soloflowUser?.stripe_customer_id && (
                        <Text
                          as={motion.p}
                          fontSize="sm"
                          color="red.500"
                          fontWeight="bold"
                          mt={4}
                          initial={{ scale: 1 }}
                          animate={{ scale: [1, 1.1, 1] }}
                          // transition={{ duration: 2, repeat: Infinity }}
                        >
                          Claim your 2-week free trial now!
                        </Text>
                      )}

                      <Button
                        as={motion.button}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        colorScheme="orange"
                        size="lg"
                        w="full"
                        // mt={4}
                        onClick={index === 2 ? onOpen : index === 1 ? modifyProMembership : () => navigate('/projects')}
                        isDisabled={index == 0 && isSubscribed}
                      >
                          {index == 1 && isSubscribed ? 'Cancel Membership' : plan.cta}
                      </Button>
                    </VStack>
                  </Box>
                </GridItem>
              ))}
            </Grid>
          </VStack>

          :

          <Box
            bg="white"
            p={6}
            borderRadius="lg"
            boxShadow="lg"
            maxW="lg"
            mx="auto"
            mt={12}
          >
            <Heading as="h2" size="lg" mb={6} textAlign="center" color="orange.500">
              Secure Checkout
            </Heading>

            { clientSecret &&
              <EmbeddedCheckoutProvider stripe={stripePromise} options={{ fetchClientSecret }}>
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            }
          </Box>
          } 
        </Container>}
      </Box>
      <Footer/>

      {/* Quote Request Modal remains the same */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request a Quote</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <Input
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                />
                <Input
                  placeholder="Your Email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  required
                />
                <Textarea
                  placeholder="Tell Us About Your Usage (2-3 Sentences)"
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  required
                />
                <Button type="submit" colorScheme="brand" w="full">
                  Send Quote Request
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      <ActionConfirmation
        prompt='Are you sure you want to cancel your subscription?'
        successMessage='Your subscription has been cancelled successfully.'
        errorMessage='An error occurred while cancelling your sub. Please reload your page and try again.'
        action={handleDowngrade}
        isOpen={isCancelSubscriptionConfirmationOpen}
        onClose={closeCancelSubscriptionConfirmation}
      />
    </ChakraProvider>
  );
};

export default PricingPage;