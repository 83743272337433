// TODO: Duplicate code with ThreadModal.tsx, refactor to use a common component for both timestamp comments and general comments

import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  useDisclosure,
  useColorModeValue,
  Text,
  IconButton,
  HStack,
  Spinner,
  Icon,
  Textarea,
  useToast,
  Tooltip,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal
} from '@chakra-ui/react'
import {
  FaComment,
  FaPaperPlane,
  FaMagic,
} from 'react-icons/fa'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'

import { BASE_URL } from '../../config'
import ReactGA from 'react-ga4'
import * as amplitude from '@amplitude/analytics-browser'

import { Version, Comment } from '../../_helpers/types'
import ErrorScreen from '../common/ErrorScreen'
import LoadingScreen from '../common/LoadingScreen'
import Waveform from './Waveform'
import axios from 'axios'
import ShareClient from '../common/ShareClient'
import EmailModal from '../common/EmailModal'
import { User } from 'firebase/auth'
import { useUser } from '../common/UserContext'
import MobileAccessErrorScreen from '../common/MobileAccessErrorScreen'
import VersionSelector from './VersionSelector'
import RenameVersion from '../project/RenameVersion'
import { socket } from '../../App'
import GoogleProvider from '../auth/GoogleProvider'
import { SharedBadge, UpgradeBadge} from '../common/ProjectBadge'
import { isProjectShared } from '../project/ProjectUtils'
import { useProjectContext } from '../project/ProjectContext'
import ActionConfirmation from '../common/ActionConfirmation'

const RevisionPage: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  const [user, loading, error] = useAuthState(auth)

  const { versions,
    currentProject, currentTrack, currentVersion, currentAudioUrl,
    loadingData, loadingError, 
    sendComment, handleNewComment,
    deleteVersion,
    setCurrentVersionId} = useProjectContext();

  const { isOpen, onOpen, onClose } = useDisclosure()


  const [newMessage, setNewMessage] = useState<string>('')
  const [aiResponse, setAIResponse] = useState<string[]>([])
  const [aiLoading, setAILoading] = useState<boolean>(false)
  const [lastUserMessage, setLastUserMessage] = useState<string>('')
  const [hasProvidedEmailOrSignedIn, setHasProvidedEmailOrSignedIn] =
    useState<boolean>(false)
  const {
    isOpen: isEmailModalOpen,
    onOpen: openEmailModal,
    onClose: closeEmailModal
  } = useDisclosure()
  const commentEndRef = useRef<HTMLDivElement>(null)
  const toast = useToast()

  const { soloflowUser, canUserEditSharedProject, openRelevantModal, openUpgradeModal } = useUser()
  // const [permissions, setPermissions] = useState<Permission[]>([])

  const [menuOpen, setMenuOpen] = useState(false)
  const [menuVersion, setMenuVersion] = useState<Version | null>(null)
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 })

  const {
    isOpen: isRenameVersionOpen,
    onOpen: openRenameVersion,
    onClose: closeRenameVersion
  } = useDisclosure()

  const {
    isOpen: isDeleteVersionConfirmationOpen,
    onOpen: openDeleteVersionConfirmation,
    onClose: closeDeleteVersionConfirmation
  } = useDisclosure()

  const [waveformKey, setWaveformKey] = useState(0)

  const [showInstructions, setShowInstructions] = useState(false)

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  useEffect(() => {
    if (isMobile()) {
      setShowSidebar(false)
    } else {
      setShowSidebar(true)
    }
  }, [setShowSidebar])

  useEffect(() => {
    if (currentProject && soloflowUser && !canUserEditSharedProject(currentProject)) {
      openUpgradeModal()
    }
  }, [soloflowUser, currentProject])

  // const fetchPermissions = useCallback(async () => {
  //   if (!projectID || !user?.uid) return
  //   const permissions = await getPermissions(projectID)
  //   setPermissions(
  //     permissions.filter((permission) => permission.email === user?.email) || []
  //   )
  // }, [projectID, user?.uid])

  // useEffect(() => {
  //   fetchPermissions()
  // }, [fetchPermissions])
  
  useEffect(() => {
    if (currentTrack) {
      socket.emit("join_chat", currentTrack.id)
      socket.on("new_chat_message", handleNewComment)
    }

    return () => {
      if (currentTrack) {
        console.log('Cleaning up comment listener for track ' + currentTrack.id)
        socket.emit("leave_chat", currentTrack.id)
        socket.off("new_chat_message", handleNewComment)
      }
    }
  }, [currentTrack])
  

  const checkEmailInLocalStorage = useCallback(() => {
    const storedEmail = localStorage.getItem('userEmail')
    return !!storedEmail
  }, [])

  const checkHasSeenInstructions = useCallback(() => {
    const hasSeenInstructions = localStorage.getItem('seenInstructions')
    return hasSeenInstructions
  }, [])

  useEffect(() => {
    if (user || checkEmailInLocalStorage()) {
      setHasProvidedEmailOrSignedIn(true)
      if (!checkHasSeenInstructions()) {
        setShowInstructions(true)
      }
    } else {
      openEmailModal()
    }
  }, [user, checkEmailInLocalStorage, onOpen])

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      name: 'Landing Page'
    })
  }, [])

  useEffect(() => {
    if (commentEndRef.current && currentTrack?.generalComments.length && currentTrack?.generalComments.length > 0) {
      commentEndRef.current.scrollIntoView({ behavior: 'smooth' })
      commentEndRef.current.scrollTop = commentEndRef.current?.scrollHeight
    }
  }, [currentTrack, currentTrack?.generalComments])


  const handleSendComment = useCallback(async () => {
    if (currentProject && !canUserEditSharedProject(currentProject)) {
      openRelevantModal()
      setNewMessage('');
      return
    }
    if (newMessage.trim() && currentTrack) {
      const commentObj: Comment = {
        content: newMessage,
        sender: user?.email || localStorage.getItem('userEmail') || 'Anonymous',
        timestamp: new Date()
      }

      setNewMessage('')
      const sendCommentResult = await sendComment(commentObj)
      if (!sendCommentResult) {
        toast({
          title: 'Error sending comment',
          description: 'An error occurred while sending your comment. Please reload your page and try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }, [newMessage, user, currentTrack, toast])

  const handleAISuggestion = async () => {
    if (newMessage.trim()) {
      setAIResponse([])
      setLastUserMessage(newMessage)
      setAILoading(true)

      try {
        const response = await axios.post(`${BASE_URL}/threads/ai`, {
          file_path: currentAudioUrl,
          message: newMessage
        })

        const rawResp = JSON.parse(response.data)

        // Parse the response (json format) to an array of strings
        const apiResp = rawResp.map((resp: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
          return resp.message
        })

        setAIResponse([...apiResp])

        // Track the AI suggestion generated
        // TODO: Should we make this more specific (by thread, version, etc.)?
        amplitude.track('AI Suggestion Generated', {
          userID: user?.uid
        })
      } catch (error) {
        console.error('Error generating AI response:', error)
        toast({
          title: 'Failed to generate AI suggestions',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      } finally {
        setAILoading(false)
      }
    }
  }

  const handleAIUpdate = async (aiMsg: string) => {
    const commentObj: Comment = {
      content: aiMsg,
      sender: user?.email || localStorage.getItem('userEmail') || 'Anonymous',
      timestamp: new Date()
    }

    setNewMessage('')
    setAIResponse([])
    const sendCommentResult = await sendComment(commentObj)
    if (sendCommentResult) {
      amplitude.track('AI Suggestion Used', {
        userID: user?.uid
      })
    } else {
      console.error('Error sending comment')
      toast({
        title: 'Error sending comment',
        description: 'An error occurred while sending your comment. Please reload your page and try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const bgColor = useColorModeValue('gray.100', 'gray.700')
  const textColor = useColorModeValue('gray.800', 'white')

  const [isVersionSelectorDisabled, setIsVersionSelectorDisabled] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleVersionSelect = (index: number) => {
    if (versions) {
      setIsVersionSelectorDisabled(true)
      setCurrentVersionId(versions[index].id)
      setWaveformKey((prevKey) => prevKey + 1)

      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      // Set a new timeout to re-enable the selector after 3 seconds
      timeoutRef.current = setTimeout(() => {
        setIsVersionSelectorDisabled(false)
      }, 2000)
    }
  }

  const handleMenuOpen = (version: Version, buttonRect: DOMRect) => {
    setMenuVersion(version)
    setMenuOpen(true)
    setMenuPosition({
      top: buttonRect.bottom,
      left: buttonRect.left
    })
  }

  const handleRenameVersion = () => {
    if (canUserEditSharedProject(currentProject)) {
      openRenameVersion()
    } else {
      openRelevantModal()
    }
  }

  const handleDeleteVersion = async (version: Version | null) => {
    if (version) {
      setMenuVersion(null)
      setMenuOpen(false)
      const deleteVersionResult = await deleteVersion(version.id)
      return deleteVersionResult
    }

    return false
  } 

  // Clean up the timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  if (loadingError && !loadingData && !currentVersion && !currentTrack && !currentProject) {
    return (
      <ErrorScreen
        error={loadingError}
        returnURL={"/projects"}
        hideHeader={true}
      />
    )
  }

  if (loading || loadingData) {
    return <LoadingScreen />
  }

  if (
    error ||
    loadingError ||
    !versions ||
    !currentTrack ||
    !currentProject
  ) {
    const sentError = error 
      || loadingError 
      || (!versions ? new Error('Versions not found') : null) 
      || (!currentTrack ? new Error('Track not found') : null)
      || (!currentProject ? new Error('Project not found') : null)
      || new Error('An unknown error occurred')
    return (
      <ErrorScreen
        error={sentError}
        returnURL={"/projects"}
        hideHeader={true}
      />
    )

  }

  if (isMobile()) {
    return <MobileAccessErrorScreen home='/' />
  }

  const isNewOrRecentUser = (user: User | null | undefined): boolean => {
    if (!user) return true

    const now = new Date()
    const lastSignInTime = user.metadata.lastSignInTime
      ? new Date(user.metadata.lastSignInTime)
      : now
    const creationTime = user.metadata.creationTime
      ? new Date(user.metadata.creationTime)
      : now

    const isFirstSignIn = creationTime.getTime() === lastSignInTime.getTime()
    // const isWithinTenMinutes = now.getTime() - lastSignInTime.getTime() <= 10 * 60 * 1000 // 10 minutes in milliseconds

    return isFirstSignIn
  }

  const isOwner =
    // (permissions.length > 0 && permissions[0].roles.includes(Role.MANAGE)) ||
    currentProject.userIDs.includes(user?.uid || '')

  const canCreateVersion =
    // (permissions.length > 0 && permissions[0].roles.includes(Role.UPLOAD)) || 
    // isOwner
    true

  const handleEmailModalClose = () => {
    closeEmailModal()
    setHasProvidedEmailOrSignedIn(true)

    if (!checkHasSeenInstructions()) {
      setShowInstructions(true)
    }
  }

  return (
    <>
      <Box color='gray.600' minHeight='100vh' px={10} py={3}>
        <Flex alignItems='center' justifyContent='space-between' mb={4}>
          <VStack align='flex-end' spacing={0}>
            <Tooltip label={currentProject.name} hasArrow>
              <Heading
                as='h1'
                size='lg'
                fontWeight='bold'
                color='gray.700'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
                maxWidth={window.innerWidth * 0.3}
              >
                {currentProject.name}
              </Heading>
            </Tooltip>
            <Tooltip label={`${currentTrack.name} ${versions.length > 0 ? "-" + currentVersion?.name : ""}`} hasArrow>
              <Heading
                as='h2'
                fontSize='xl'
                color='gray.500'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
                maxWidth={window.innerWidth * 0.5}
              >
                {currentTrack.name} {versions.length > 0 ? "-" + currentVersion?.name : ""}
              </Heading>
            </Tooltip>
          </VStack>

          <HStack>

            {user && !canUserEditSharedProject(currentProject) && <UpgradeBadge text={"Upgrade to edit"}/>}
            {isProjectShared(currentProject) && <SharedBadge/>}

            <Button
              onClick={onOpen}
              leftIcon={<FaComment />}
              colorScheme='orange'
              variant='outline'
              _hover={{ bg: 'orange.400', color: 'white' }}
            >
              Chat
            </Button>

            {user && (
              <ShareClient/>
            )}

            {!user && (
              <GoogleProvider returnUrl={window.location.href} shouldSave={false}></GoogleProvider>
            )}

          </HStack>
        </Flex>

        {versions.length > 0 && (
          <Waveform
            key={waveformKey}
            isComplete={false}
            // roles={permissions.length > 0 ? permissions[0].roles : []}
            isOwner={currentProject.userIDs.includes(user?.uid || '')}
          />
        )}

        <VersionSelector
          onVersionSelect={handleVersionSelect}
          isOwner={isOwner}
          isDisabled={isVersionSelectorDisabled}
          onMenuOpen={handleMenuOpen}
          canCreateVersion={canCreateVersion}
        />

        {/* Chat Drawer */}
        <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
          <DrawerOverlay />
          <DrawerContent bg={bgColor}>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth='1px'>
              <Heading size='lg' color={textColor}>
                Chat
              </Heading>
              <Text fontSize='sm' color='gray.500'>
                Comments made here are visible to all collaborators
              </Text>
              <Text fontSize='sm' color='gray.500'>
                You are viewing track <strong>{currentTrack?.name}</strong> in project <strong>{currentProject?.name}</strong>.
              </Text>
            </DrawerHeader>
            <DrawerBody display='flex' flexDirection='column'>
              <VStack spacing={4} align='stretch' flex={1} overflowY='auto' mb={4}>
                { currentTrack?.generalComments ?
                currentTrack?.generalComments.map((comment: Comment, index: number) => {
                  const isUserComment =
                    comment.sender ===
                    (user?.email || localStorage.getItem('userEmail') || 'Anonymous')

                  return (
                    <Flex
                      key={index} // Use index as key since Comment type doesn't have an id
                      justifyContent={isUserComment ? 'flex-end' : 'flex-start'}
                    >
                      <Box
                        maxWidth='70%'
                        bg={isUserComment ? 'blue.500' : 'gray.200'}
                        color={isUserComment ? 'white' : 'black'}
                        p={3}
                        borderRadius='lg'
                        borderTopRightRadius={isUserComment ? 0 : 'lg'}
                        borderTopLeftRadius={isUserComment ? 'lg' : 0}
                      >
                        {!isUserComment && (
                          <Text fontWeight='bold'>{comment.sender}</Text>
                        )}
                        <Text
                          fontSize='sm'
                          color={isUserComment ? 'blue.100' : 'gray.500'}
                          mb={1}
                        >
                          {new Date(comment.timestamp).toLocaleString()}
                        </Text>
                        <Text>{comment.content}</Text>
                      </Box>
                    </Flex>
                  )
                }) : null}
                <div ref={commentEndRef} />
              </VStack>
              {aiLoading && (
                <Spinner
                  thickness='3px'
                  color='orange.500'
                  size='lg'
                  justifySelf='center'
                  alignSelf='center'
                  mb={3}
                />
              )}
              {aiResponse.length !== 0 && (
                <VStack align='stretch' spacing={3}>
                  <Text fontSize='lg' fontWeight='bold'>
                    Select a response that best matches your feedback:
                  </Text>
                  <HStack spacing={4} alignItems='stretch' overflowX='auto' pb={2}>
                    <AIResponseBox
                      response={lastUserMessage}
                      handleAIUpdate={handleAIUpdate}
                      aiGenerated={false}
                    />
                    {aiResponse.map((response, index) => (
                      <AIResponseBox
                        key={index}
                        response={response}
                        handleAIUpdate={handleAIUpdate}
                        aiGenerated={true}
                      />
                    ))}
                  </HStack>
                </VStack>
              )}
              <HStack
                as='form'
                onSubmit={(e: React.FormEvent) => {
                  e.preventDefault()
                  handleSendComment()
                }}
              >
                <Input
                  value={newMessage}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewMessage(e.target.value)
                  }
                  placeholder={'Type your comment...'}
                  bg='white'
                  color={textColor}
                />
                <Tooltip label='COMING SOON: Translate your ideas into actionable suggestions!' hasArrow>
                  <IconButton
                    icon={<FaMagic />}
                    colorScheme='orange'
                    onClick={handleAISuggestion}
                    isDisabled={!newMessage.trim() || true}
                    aria-label='AI Suggest'
                  />
                </Tooltip>
                
                <IconButton
                  icon={<FaPaperPlane />}
                  colorScheme='blue'
                  isDisabled={!newMessage.trim()}
                  type='submit'
                  aria-label='Send comment'
                />
              </HStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <EmailModal
          isOpen={isEmailModalOpen && !hasProvidedEmailOrSignedIn}
          onClose={handleEmailModalClose}
          setHasProvidedEmailOrSignedIn={setHasProvidedEmailOrSignedIn}
        />
      </Box>

      <InstructionsModal
        isOpen={showInstructions && isNewOrRecentUser(user)}
        onClose={() => {
          localStorage.setItem('seenInstructions', 'true')
          setShowInstructions(false)
        }}
      />

      <Portal>
        <Menu
          isOpen={menuOpen}
          onClose={() => setMenuOpen(false)}
          closeOnBlur={true}
        >
          <MenuButton
            style={{ position: 'fixed', top: menuPosition.top, left: menuPosition.left }}
          />
          <MenuList>
            <MenuItem onClick={handleRenameVersion}>Rename Version</MenuItem>
            <MenuItem onClick={(e) => {
                e.stopPropagation()
                if (!canUserEditSharedProject(currentProject)) {
                  openRelevantModal()
                  return
                }
                openDeleteVersionConfirmation() 
              }
            }>Delete Version</MenuItem>
          </MenuList>
        </Menu>
      </Portal>

      <RenameVersion
        isOpen={isRenameVersionOpen}
        onClose={() => {
          setMenuOpen(false)
          setMenuVersion(null)
          closeRenameVersion()
        }}
        version={menuVersion!} // eslint-disable-line @typescript-eslint/no-non-null-assertion
        // fetchVersions={fetchVersions}
      />
      <ActionConfirmation
        prompt='Are you sure you want to delete this version?'
        successMessage='The version has been deleted.'
        errorMessage='An error occurred while deleting the version. Please reload your page and try again.'
        action={() => handleDeleteVersion(menuVersion)}
        isOpen={isDeleteVersionConfirmationOpen}
        onClose={closeDeleteVersionConfirmation}
      />
    </>
  )
}

interface AIResponseBoxProps {
  response: string
  handleAIUpdate: (aiMsg: string) => void
  aiGenerated: boolean
}

const AIResponseBox: React.FC<AIResponseBoxProps> = ({
  response,
  handleAIUpdate,
  aiGenerated
}) => {
  const [editResponse, setEditResponse] = useState(response)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleSaveClick = () => {
    handleAIUpdate(editResponse)
  }

  const textAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditResponse(e.target.value)
    adjustTextareaHeight()
  }

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = textarea.scrollHeight + 'px'
    }
  }

  return (
    <Box
      bg={aiGenerated ? 'orange.100' : 'gray.100'}
      p={3}
      borderRadius='lg'
      borderTopLeftRadius={0}
      minWidth='200px'
      maxWidth='300px'
      flexShrink={0}
    >
      <Text fontWeight='bold' mb={1}>
        {aiGenerated ? 'AI Suggestion' : 'Original Comment'}
      </Text>
      <Text fontSize='sm' color='gray.500' mb={2}>
        {new Date().toLocaleString()}
      </Text>
      <Textarea
        ref={textareaRef}
        value={editResponse}
        onChange={textAreaChange}
        onInput={adjustTextareaHeight}
        size='sm'
        variant='filled'
        resize='none'
        minH='100px'
        mb={2}
        bg='white'
      />
      <Flex justifyContent='flex-end'>
        <IconButton
          icon={<Icon as={FaPaperPlane} />}
          size='sm'
          colorScheme='green'
          onClick={handleSaveClick}
          aria-label='Save response'
        />
      </Flex>
    </Box>
  )
}

const InstructionsModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent color='orange.600'>
        <ModalHeader color='black'>Waveform Controls</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align='start' spacing={4}>
            <Text>
              <strong style={{ color: 'black' }}>Create a Thread:</strong> Click and drag
              to create a new comment thread
            </Text>
            <Text>
              <strong style={{ color: 'black' }}>Open a Thread:</strong> Double click on
              any highlighted region to open it
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button leftIcon={<FaPaperPlane />} colorScheme='orange' onClick={onClose}>
            Get Started
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RevisionPage
