import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Input,
  VStack,
  Text
} from '@chakra-ui/react'

import { Version } from '../../_helpers/types'
import { useProjectContext } from './ProjectContext'

interface RenameVersionProps {
  version: Version
  isOpen: boolean
  onClose: () => void
}

const RenameVersion = ({
  version,
  isOpen,
  onClose,
}: RenameVersionProps) => {
  const [newVersionName, setNewVersionName] = React.useState<string>('')
  const [renamingVersion, setRenamingVersion] = useState(false)

  const { renameVersion} = useProjectContext();

  const toast = useToast()

  const handleRenameVersion = async () => {
    setRenamingVersion(true)
    const renameVersionResult = await renameVersion(version.id, newVersionName)
    setRenamingVersion(false)
    onClose()

    if (renameVersionResult) {
      toast({
        title: 'Version Renamed',
        description: 'Your version has been renamed successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Error',
        description: `There was an error renaming the version. Please reload your page and try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderColor='orange.200' borderWidth={1}>
        <ModalHeader color='orange.800'>Rename Version</ModalHeader>
        <ModalCloseButton color='orange.800' />
        <ModalBody>
          <VStack spacing={4} align='stretch'>
            <Text color='orange.800'>Enter a new name for the version:</Text>
            <Input
              type='text'
              value={newVersionName}
              onChange={(e) => setNewVersionName(e.target.value)}
              size='md'
              variant='filled'
              bg='beige'
              _hover={{ bg: 'orange.100' }}
              _focus={{ bg: 'orange.100', borderColor: 'orange.300' }}
              color='orange.800'
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            bg='orange.400'
            color='white'
            _hover={{ bg: 'orange.500' }}
            onClick={handleRenameVersion}
            isLoading={renamingVersion}
            isDisabled={newVersionName === '' || newVersionName.replace(/\s/g, '') === ''}
            size='md'
          >
            Rename
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RenameVersion
