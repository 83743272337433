// Deleting Project Disabled For Now

import React, { useEffect, useState } from 'react'
import { Box, Flex, Text, Button, useDisclosure, useColorModeValue, Icon, HStack, Menu, MenuItem, MenuList, IconButton, MenuButton, useToast } from '@chakra-ui/react'
import { FaFolder, FaPlus, FaEdit, FaEllipsisV } from 'react-icons/fa'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

import LoadingScreen from '../common/LoadingScreen'
import ErrorScreen from '../common/ErrorScreen'
import { Project } from '../../_helpers/types'
import CreateProject from './CreateProject'
import RenameProject from './RenameProject'
import { SharedBadge, UpgradeBadge } from '../common/ProjectBadge'
import { isProjectShared } from './ProjectUtils'
import { useProjectContext } from './ProjectContext'
import { useUser } from '../common/UserContext'

const ProjectsHome: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    name: 'Projects Home'
  })

  const { projects, loadingData, loadingError, renameProject} = useProjectContext();
  const { canUserEditSharedProject } = useUser();

  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const projectHoverBgColor = useColorModeValue('orange.200', 'gray.700')

  const [filter, setFilter] = useState(false)
  const [filterStatus, setFilterStatus] = useState(false)
  const [selectedProject, setSelectedProject] = useState<Project | null>(null)
  const { isOpen: isRenameOpen, onOpen: onRenameOpen, onClose: onRenameClose } = useDisclosure()
  
  const toast = useToast()

  useEffect(() => {
    setShowSidebar(true)
  }, [])

  if (loading || loadingData) {
    return <LoadingScreen />
  }

  if (!user) {
    const currentPath = `/projects`
    navigate(`/login?returnUrl=${encodeURIComponent(currentPath)}`)
  }

  if (error || loading || loadingError) {
    return <ErrorScreen error={(error || loadingError) as Error} />
  }

  const handleFilter = (newFilter: boolean) => {
    if (filterStatus == false) {
      setFilterStatus(true)
      setFilter(newFilter)
    } else if (filterStatus == true) {
      if (filter == newFilter) {
        setFilterStatus(false)
      } else {
        setFilter(newFilter)
      }
    }
  }

  // const handleDeleteProject = async (project: Project) => {
  //   if (window.confirm(`Are you sure you want to delete the project "${project.name}"?`)) {
  //     const deleteProjectResult = await deleteProject(project.id)
  //     if (deleteProjectResult) {
  //       toast({
  //         title: "Project deleted",
  //         description: `The project "${project.name}" has been successfully deleted.`,
  //         status: "success",
  //         duration: 5000,
  //         isClosable: true,
  //       })
  //     } else {
  //       toast({
  //         title: "Error",
  //         description: `There was an error deleting the project "${project.name}". Please reload your page and try again.`,
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //       })
  //     }
  //   }
  // }

  return (
    <Flex>
      <Box bg='beige' minHeight='100vh' color='black' flex='1'>
        <Flex direction='column' margin='0 auto' p={8}>
          <Flex justify='space-between' align='center' mb={6}>
            <Flex align='center'>
              <Text fontSize='4xl' fontWeight='bold'>
                Library
              </Text>
            </Flex>
          </Flex>

          {/* <Flex mb={6}> */}
            {/* Add more informative views here based on customer feedback */}
          {/* </Flex> */}

          <Flex justifyContent='space-between'>
            <Flex>
              <Button colorScheme='orange' mr={2} onClick={onOpen}>
                New Project
              </Button>
            </Flex>
            <HStack mb={4} justifyContent={'flex-end'}>
              <Text>Filter By:</Text>
              <Button
                bgColor={!filter && filterStatus ? 'red.400' : 'orange.100'}
                borderRadius='md'
                _hover={{
                  bg: !filter && filterStatus ? '' : 'orange.200',
                  cursor: 'pointer'
                }}
                onClick={() => handleFilter(false)}
              >
                Active
              </Button>
              <Button
                bgColor={filter && filterStatus ? 'green.500' : 'orange.100'}
                borderRadius='md'
                _hover={{
                  bg: filter && filterStatus ? '' : 'orange.200',
                  cursor: 'pointer'
                }}
                onClick={() => handleFilter(true)}
              >
                Completed
              </Button>
            </HStack>
          </Flex>

          {projects &&
            projects
              .filter((project: Project) => {
                if (filterStatus) {
                  return filter ? project.isComplete : !project.isComplete
                } else {
                  return true
                }
              })
              .sort((a: Project, b: Project) => {
                const dateA = new Date(a.createdAt)
                const dateB = new Date(b.createdAt)
                return dateA.getTime() - dateB.getTime()
              })
              .reverse()
              .map((project: Project) => (
                <Flex
                  key={project.id}
                  bg='orange.100'
                  p={4}
                  borderRadius='md'
                  mb={4}
                  align='center'
                  _hover={{ bg: 'orange.200', cursor: 'pointer' }}
                  onClick={() => navigate(`/projects/${project.id}`)}
                >
                  <Icon as={FaFolder} boxSize={4} mr={4} />
                  <Box width='100%'>
                    <HStack justifyContent={'space-between'}>
                      <Text fontWeight='bold'>{project.name}</Text>

                      <HStack>
                        {user && !canUserEditSharedProject(project) && <UpgradeBadge/>}
                        {isProjectShared(project) && (<SharedBadge/>)}
                        <Box p={1} rounded={4}>
                          <Text
                            color={project.isComplete ? 'green.500' : 'red.400'}
                            fontSize='sm'
                            textAlign={'center'}
                            fontWeight={'bold'}
                          >
                            {project.isComplete ? 'Completed' : 'Active'}
                          </Text>
                        </Box>

                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<FaEllipsisV />}
                            variant='ghost'
                            aria-label='Project Options'
                            borderRadius='full'
                            _hover={{ bg: 'orange.400' }}
                            _active={{ bg: projectHoverBgColor }}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          />
                          <MenuList>
                            <MenuItem
                              icon={<FaEdit />}
                              onClick={(e) => {
                                e.stopPropagation()
                                setSelectedProject(project)
                                onRenameOpen()
                              }}
                            >
                              Rename Project
                            </MenuItem>
                            {/* <MenuItem
                              icon={<FaTrash />}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleDeleteProject(project)
                              }}
                            >
                              Delete Project
                            </MenuItem> */}
                          </MenuList>
                        </Menu>
                      </HStack>
                    </HStack>
                    <Text color='gray.600' fontSize='sm' mt={2}>
                    {project.createdAt 
                      ? `Created on ${project.createdAt.split('T')[0]} by ${user?.email === project.createdBy ? 'you' : project.createdBy}` 
                      : 'Just Created'}
                  </Text>
                  </Box>
                </Flex>
              ))}

          <HStack justifyContent={'space-between'}>
            <Button
              leftIcon={<FaPlus />}
              colorScheme='orange'
              variant='ghost'
              onClick={onOpen}
              alignSelf='flex-start'
            >
              New Project
            </Button>
            <Text color='gray.600'>
              {projects
                ? filterStatus
                  ? projects.filter((project: Project) =>
                      filter ? project.isComplete : !project.isComplete
                    ).length
                  : projects.length
                : 0}{' '}
              Results
            </Text>
          </HStack>
        </Flex>

        <CreateProject isOpen={isOpen} onClose={onClose} />
        {selectedProject && (
          <RenameProject
            project={selectedProject}
            isOpen={isRenameOpen}
            onClose={() => {
              onRenameClose()
              setSelectedProject(null)
            }}
            onRename={async(updatedProject: Project, newProjectName : string) => {
              const successToastId = toast({
                title: "Project renamed",
                description: `The project "${updatedProject.name}" has been successfully renamed to "${newProjectName}".`,
                status: "success",
                duration: 5000,
                isClosable: true,
              })
              const renameProjectResult = await renameProject(updatedProject.id, newProjectName)
              if (!renameProjectResult) {
                if (successToastId) {
                  toast.close(successToastId)
                }
                toast({
                  title: "Error",
                  description: `There was an error renaming the project "${updatedProject.name}". Please reload your page and try again.`,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                })
              }
            }}
          />
        )}
      </Box>
    </Flex>
  )
}

export default ProjectsHome