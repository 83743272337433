import React, { useState, useMemo, useEffect } from 'react'
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  Badge,
  IconButton,
  Avatar,
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  RadioGroup,
  Radio,
  Checkbox,
  Divider,
  Tag,
  Tooltip,
  Circle
} from '@chakra-ui/react'
import { FaEllipsisV, FaFilter } from 'react-icons/fa'
import ThreadModal from './ThreadModal'
import { CheckIcon } from '@chakra-ui/icons'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNotification } from '../common/NotificationContext'
import { useProjectContext } from '../project/ProjectContext'
import { Thread } from '../../_helpers/types'
import { useUser } from '../common/UserContext'

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(Math.ceil(seconds) % 60)
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}

type ThreadCarouselProps = {
  handleThreadClick: (region: unknown) => void
  isOwner: boolean
}

const ThreadCarousel: React.FC<ThreadCarouselProps> = ({
  handleThreadClick,
  isOwner
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [user] = useAuthState(auth)
  const toast = useToast()

  const [currentThread, setCurrentThread] = useState<Thread | null>(null)

  const bgColor = 'beige'
  const textColor = 'black'
  const subTextColor = 'gray.600'
  const cardBgColor = 'orange.100'

  const {threads, toggleThreadResolved, currentProject} = useProjectContext();
  const { openRelevantModal, canUserEditSharedProject} = useUser()

  const [filterState, setFilterState] = useState<'all' | 'resolved' | 'unresolved'>('all')
  const [filterUsers, setFilterUsers] = useState<Set<string>>(new Set())
  const uniqueUsers = useMemo(() => {
    return Array.from(new Set(threads?.map((thread) => thread.messages[0].sender)))
  }, [threads])

  const [sortedThreads, setSortedThreads] = useState<Thread[]>([])

  const filteredThreads = useMemo(() => {
    return sortedThreads.filter((thread) => {
      const stateFilter =
        filterState === 'all' ||
        (filterState === 'resolved' && thread.isResolved) ||
        (filterState === 'unresolved' && !thread.isResolved)
      const userFilter =
        filterUsers.size === 0 || filterUsers.has(thread.messages[0].sender)
      return stateFilter && userFilter
    })
  }, [sortedThreads, filterState, filterUsers])

  const {
    unreadNotifications,
    fetchUnreadNotifications,
    markRelatedNotificationsAsRead
  } = useNotification() // Use notification context

  useEffect(() => {
    // Sort threads whenever unreadNotifications changes
    if (threads) {
      const newSortedThreads = [...threads].sort((a: Thread, b: Thread) => {
        // First, sort by unread messages
        const unreadA = unreadNotifications[a.id] || 0
        const unreadB = unreadNotifications[b.id] || 0
        if (unreadA > unreadB) return -1
        if (unreadA < unreadB) return 1

        // If unread count is the same, sort by resolved status
        if (!a.isResolved && b.isResolved) return -1
        if (a.isResolved && !b.isResolved) return 1

        // If resolved status is the same, sort by region start time
        return a.region.start - b.region.start
      })

      setSortedThreads(newSortedThreads)
    }
  }, [threads, unreadNotifications])

  useEffect(() => {
    if (user) {
      fetchUnreadNotifications(user.uid) // Fetch notifications from the context
    }
  }, [])

  const handleUserFilterChange = (user: string) => {
    setFilterUsers((prevUsers) => {
      const newUsers = new Set(prevUsers)
      if (newUsers.has(user)) {
        newUsers.delete(user)
      } else {
        newUsers.add(user)
      }
      return newUsers
    })
  }

  const handleOpenReplies = async (thread: Thread) => {
    setCurrentThread(thread)
    onOpen()
    // Clear unread notifications for this thread
    if (thread && user) {
      markRelatedNotificationsAsRead(thread.id, user.uid, toast)
    }
  }

  const toggleThreadResolve = async (thread: Thread) => {
    if (!canUserEditSharedProject(currentProject)) {
      openRelevantModal()
      return false
    }
    
    if (thread) {
      const successToastId = toast({
        title: thread?.isResolved ? 'Thread reopened!': 'Thread resolved!',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
      const toggleThreadResolveResult = await toggleThreadResolved(thread.id)

      if (!toggleThreadResolveResult) {
        if (successToastId) {
          toast.close(successToastId);
        }
        toast({
          title: `Failed to toggle thread resolution. Please reload your page and try again.`,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }
  }

  const handleCardClick = (thread: Thread) => {
    if (
      thread.region &&
      thread.region.start !== undefined &&
      thread.region.end !== undefined
    ) {
      handleThreadClick(thread.region)
    }
  }

  return (
    <Box
      mx='auto'
      mb={8}
      bg={bgColor}
      borderRadius='lg'
      overflow='hidden'
      boxShadow='lg'
      maxHeight='44vh'
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
    >
      <Flex justifyContent='space-between' align='center' p={4}>
        {threads && threads.length > 0 ? (
          <>
            <Box
              display='flex'
              alignContent='center'
              justifyContent='center'
              width='100%'
              height='auto'
            >
              <Text
                textAlign='center'
                fontSize='lg'
                fontWeight='bold'
                color='orange.400'
                width='auto'
                height='auto'
                alignSelf='center'
                justifySelf='center'
              >
                {'Drag out a region and click on it to create a comment! 🚀'}
              </Text>
            </Box>
            <Menu closeOnSelect={false}>
              <div style={{ width: 'auto' }}>
                <MenuButton
                  as={Button}
                  leftIcon={<FaFilter />}
                  colorScheme='orange'
                  ml='auto'
                >
                  {filteredThreads.length} comment(s)
                </MenuButton>
              </div>
              <MenuList>
                <MenuItem _hover={{ bg: 'white' }}>
                  <RadioGroup
                    onChange={(value) =>
                      setFilterState(value as 'all' | 'resolved' | 'unresolved')
                    }
                    value={filterState}
                  >
                    <VStack align='start'>
                      <Text fontWeight='bold' mb={2}>
                        Filter By State
                      </Text>
                      <Radio value='unresolved'>Show Open</Radio>
                      <Radio value='resolved'>Show Resolved</Radio>
                      <Radio value='all'>Show All</Radio>
                    </VStack>
                  </RadioGroup>
                </MenuItem>
                <Divider my={2} />
                <MenuItem _hover={{ bg: 'white' }}>
                  <VStack align='start'>
                    <Text fontWeight='bold' mb={2}>
                      Filter By Commenter
                    </Text>
                    {uniqueUsers.map((user) => (
                      <Checkbox
                        key={user}
                        isChecked={filterUsers.has(user)}
                        onChange={() => handleUserFilterChange(user)}
                      >
                        <HStack>
                          <Avatar name={user} size='xs' />
                          <Text>{user}</Text>
                        </HStack>
                      </Checkbox>
                    ))}
                  </VStack>
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ) : null}
      </Flex>
      <Box display='flex' overflowX='auto' px={10} height='30vh'>
        {(threads?.length && threads.length > 0) ? (
          filteredThreads.length > 0 ? (
            <HStack spacing={6}>
              {filteredThreads.map((thread, index) => (
                <Box
                  key={index}
                  width='350px'
                  height='170px'
                  bg={cardBgColor}
                  borderRadius='lg'
                  boxShadow='md'
                  p={4}
                  onClick={() => handleCardClick(thread)}
                  cursor='pointer'
                  position='relative'
                >
                  {unreadNotifications[thread.id] > 0 && (
                    <Tooltip label={`${unreadNotifications[thread.id]} unread messages`}>
                      <Circle
                        size='10px'
                        bg='red.500'
                        position='absolute'
                        top={2}
                        right={2}
                      />
                    </Tooltip>
                  )}
                  <VStack
                    align='stretch'
                    spacing={3}
                    display='flex'
                    justifyContent='space-between'
                    height='100%'
                  >
                    <Flex justify='space-between' align='center'>
                      <HStack>
                        <Avatar name={thread.messages[0].sender} size='sm' />
                        <Text
                          fontWeight='medium'
                          color={textColor}
                          maxW={thread.isResolved ? '150px' : '200px'} // Set a max width to control overflow
                          isTruncated
                          overflow='hidden'
                          whiteSpace='nowrap'
                          textOverflow='ellipsis'
                        >
                          {thread.messages[0].sender}
                        </Text>
                      </HStack>
                      <HStack>
                        <Text fontSize='sm' color={subTextColor}>
                          {formatTime(thread.region.start)}
                        </Text>
                        {thread.isResolved && (
                          <Tooltip label='Resolved'>
                            <Tag
                              as={IconButton}
                              icon={<CheckIcon />}
                              color='green.500'
                              bg='green.100'
                              borderRadius='full'
                              width='auto'
                              height='auto'
                              _hover={{ bg: 'green.100' }}
                            />
                          </Tooltip>
                        )}
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              icon={<FaEllipsisV />}
                              colorScheme='orange'
                              variant='ghost'
                              size='sm'
                            />
                            <MenuList>
                              <MenuItem onClick={() => toggleThreadResolve(thread)}>
                                {thread.isResolved ? 'Reopen Conversation' : 'Resolve'}
                              </MenuItem>
                            </MenuList>
                          </Menu>
                      </HStack>
                    </Flex>
                    <Text
                      color={textColor}
                      fontWeight='semibold'
                      noOfLines={2}
                      overflow='hidden'
                      textOverflow='ellipsis'
                    >
                      {thread.messages[0].content}
                    </Text>
                    <Flex justify='space-between' align='center' mt='auto'>
                      {unreadNotifications[thread.id] > 0 && (
                        <Badge
                          colorScheme='red'
                          borderRadius='full'
                          px={2}
                          py={1}
                          cursor='pointer'
                          onClick={(e) => {
                            e.stopPropagation()
                            handleOpenReplies(thread)
                          }}
                        >
                          {unreadNotifications[thread.id]} unread{' '}
                          {unreadNotifications[thread.id] === 1 ? 'message' : 'messages'}
                        </Badge>
                      )}
                      <Box display='flex' justifyContent='flex-end' width='100%'>
                        <Button
                          size='sm'
                          colorScheme='orange'
                          borderRadius='full'
                          onClick={(e) => {
                            e.stopPropagation()
                            handleOpenReplies(thread)
                          }}
                        >
                          Reply
                        </Button>
                      </Box>
                    </Flex>
                  </VStack>
                </Box>
              ))}
            </HStack>
          ) : null
        ) : (
          <Box
            display='flex'
            alignContent='center'
            justifyContent='center'
            width='100%'
            height='auto'
          >
            <Text
              textAlign='center'
              fontSize='lg'
              fontWeight='bold'
              color='orange.400'
              width='50%'
              height='auto'
              alignSelf='center'
              justifySelf='center'
            >
              {'Drag out a region and click on it '}
              {'to create your first comment! 🚀'}
            </Text>
          </Box>
        )}
      </Box>

      <ThreadModal
        isOpen={isOpen}
        onClose={ () => { onClose(); setCurrentThread(null) } }
        region={currentThread?.region}
        threadParam={currentThread}
        filePath={currentThread?.region.filePath}
        isOwner={isOwner}
      />
    </Box>
  )
}

export default ThreadCarousel
