import React from 'react'
import {
  useToast,
  Box,
  Heading,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  VStack,
  Text,
  Flex,
  useColorModeValue
} from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'

import { Version } from '../../_helpers/types'
import { useProjectContext } from './ProjectContext'

interface MarkFinalVersionProps {
  isOpen: boolean
  onClose: () => void
}

const MarkFinalVersion = (props: MarkFinalVersionProps) => {
  const toast = useToast()

  const bgColor = useColorModeValue('gray.50', 'gray.700')
  const textColor = useColorModeValue('gray.800', 'white')
  const buttonBgColor = useColorModeValue('orange.100', 'orange.700')
  const buttonHoverBgColor = useColorModeValue('orange.200', 'orange.600')

  const [isMarkingFinalVersion, setIsMarkingFinalVersion] = React.useState(false)
  const [markedVersionId, setMarkedVersionId] = React.useState<string | null>(null)

  const { currentTrack, versions, markFinalVersion, unmarkFinalVersion} = useProjectContext();

  const handleMarkFinalVersion = async (version: Version) => {
    if (version.isFinal) {
      setIsMarkingFinalVersion(true)
      setMarkedVersionId(version.id)
      const unmarkFinalVersionResult = await unmarkFinalVersion(version.id)
      setIsMarkingFinalVersion(false)

      if (unmarkFinalVersionResult) {
        toast({
          title: 'Final Version Unmarked',
          description: 'Your final version has been unmarked successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } else {
        toast({
          title: 'Error',
          description: `There was an error unmarking the final version. Please reload your page and try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    } else {
      setIsMarkingFinalVersion(true)
      setMarkedVersionId(version.id)
      const unmarkFinalVersionResult = await markFinalVersion(version.id)
      setIsMarkingFinalVersion(false)

      if (unmarkFinalVersionResult) {
        toast({
          title: 'Final Version Marked',
          description: 'Your final version has been marked successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } else {
        toast({
          title: 'Error',
          description: `There was an error marking the final version. Please reload your page and try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }

    props.onClose()
  }

  const handleClose = () => {
    props.onClose()
  }

  return (
    <Modal isOpen={props.isOpen} onClose={handleClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent bg={bgColor}>
        <ModalHeader>
          <Heading as='h2' size='lg' color={textColor}>
            Mark Final Version
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Box p={6} borderRadius='lg' boxShadow='md'>
            <Heading as='h3' size='md' mb={4} color={textColor}>
              {currentTrack?.name}
            </Heading>
            <Text mb={4} color={textColor}>
              Select a version to mark as final:
            </Text>
            <VStack spacing={3} align='stretch'>
              {(versions || [])
                .map((version) => (
                  <Flex
                    key={version.id}
                    justify='space-between'
                    align='center'
                    bg={version.isFinal ? 'green.100' : buttonBgColor}
                    p={3}
                    borderRadius='md'
                    boxShadow='sm'
                    _hover={{
                      boxShadow: 'md',
                      bg: version.isFinal ? 'green.200' : buttonHoverBgColor
                    }}
                    transition='all 0.2s'
                  >
                    <Text fontWeight='medium' color={textColor}>
                      {version.name}
                    </Text>
                    <Button
                      onClick={() => handleMarkFinalVersion(version)}
                      isLoading={isMarkingFinalVersion && markedVersionId === version.id}
                      isDisabled={isMarkingFinalVersion}
                      colorScheme={version.isFinal ? 'red' : 'orange'}
                      size='sm'
                      leftIcon={version.isFinal ? <FaCheckCircle /> : undefined}
                    >
                      {version.isFinal ? 'Unmark' : 'Mark as Final'}
                    </Button>
                  </Flex>
                ))}
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default MarkFinalVersion
