import React, { useState, useEffect } from 'react'
import {
  Box,
  VStack,
  HStack,
  Heading,
  Text,
  Button,
  IconButton,
  useColorModeValue,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useToast,
  Center,
  Tooltip
} from '@chakra-ui/react'
import { FaPlay, FaEllipsisV, FaTrash, FaEdit } from 'react-icons/fa'
import { Project, Track } from '../../_helpers/types'
import { BASE_URL } from '../../config'
import LoadingScreen from '../common/LoadingScreen'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import CreateTrack from './CreateTrack'
import TrackDetails from './TrackDetails'
import ShareClient from '../ShareClient'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
// import { getPermissions, Role } from '../../api/permission/permissions'
import CompleteProject from './CompleteProject'
import GoogleProvider from '../auth/GoogleProvider'
import MobileAccessErrorScreen from '../common/MobileAccessErrorScreen'
import ErrorScreen from '../common/ErrorScreen'


const ProjectTracks: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  const [user] = useAuthState(auth)
  // const [permissions, setPermissions] = useState<Permission[]>([])

  const { projectID } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const [projectData, setProjectData] = useState<Project | null>(null)
  const [projectError, setProjectError] = useState<boolean>(false)
  const [tracksData, setTracksData] = useState<Track[]>([])
  const [track, setTrack] = useState<Track | null>(null)
  const {
    isOpen: isTrackDetailsOpen,
    onOpen: onOpenTrackDetails,
    onClose: onCloseTrackDetails
  } = useDisclosure()

  const {
    isOpen: isCompleteProjectOpen,
    onOpen: onOpenCompleteProject,
    onClose: onCloseCompleteProject
  } = useDisclosure()

  const {
    isOpen: isCreateTrackOpen,
    onOpen: onOpenCreateTrack,
    onClose: onCloseCreateTrack
  } = useDisclosure()

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  useEffect(() => {
    if (isMobile()) {
      setShowSidebar(false)
    } else {
      setShowSidebar(true)
    }
  }, [setShowSidebar])

  useEffect(() => {
    const fetchProjectData = async () => {
      const projectResponse = await axios.get(`${BASE_URL}/projects/${projectID}`)
      if (projectResponse.data.code !== 404) {
        setProjectData(projectResponse.data)
        setProjectError(false)
      } else {
        setProjectData(null)
        setProjectError(true)
      }
    }

    fetchProjectData()
  }, [projectID])

  const fetchTracksData = async () => {
    const tracksResponse = await axios.get(`${BASE_URL}/tracks?projectID=${projectID}`)
    setTracksData(tracksResponse.data)
  }

  // const fetchPermissions = useCallback(async () => {
  //   if (!projectID || !user?.uid) return
  //   const permissions = await getPermissions(projectID, user?.uid)
  //   setPermissions(permissions || [])
  // }, [projectID, user?.uid])

  useEffect(() => {
    fetchTracksData()
  }, [projectID])

  // useEffect(() => {
  //   fetchPermissions()
  // }, [fetchPermissions])

  const bgColor = useColorModeValue('beige', 'gray.900')
  const textColor = useColorModeValue('black', 'white')
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')
  const trackBgColor = useColorModeValue('orange.100', 'gray.800')
  const trackHoverBgColor = useColorModeValue('orange.200', 'gray.700')

  // const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0]
  //   if (file) {
  //     const formData = new FormData()
  //     formData.append('file', file)

  //     try {
  //       await axios.post(`${BASE_URL}/tracks`, formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data'
  //         }
  //       })

  //       fetchTracksData()
  //     } catch (error) {
  //       console.error('Error uploading file:', error)
  //     }
  //   }
  // }

  const handleDeleteTrack = async (trackID: string) => {
    if (window.confirm(`Are you sure you want to delete the track?`)) {
      await axios.delete(`${BASE_URL}/tracks/${trackID}`)
      fetchTracksData()
      
       // Update project data's numTracks count locally
       setProjectData((prev) => {
        if (!prev) return prev; // If prev is null, return null
        return {
          ...prev,
          numTracks: prev.numTracks - 1  // Decrement numTracks by 1
        };
      });
  
      toast({
        title: 'Track deleted',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    }
  }

  if (isMobile()) {
    return <MobileAccessErrorScreen home='/' />
  }

  if (projectError) return <ErrorScreen
    error={new Error('Project not found. Enter a valid URL.')}
    returnURL={"/projects"}
    hideHeader={true}
  />

  if (!projectData) return <LoadingScreen />

  const isOwner =
    // permissions.some((permission) => permission.roles.includes(Role.MANAGE)) ||
    user && (projectData.userIDs.includes(user?.uid || '') ||
    projectData.userID === user?.uid)

  const canCreateTrack = 
    // permissions.some(
    //   (permission) =>
    //     permission.roles.includes(Role.UPLOAD) || permission.roles.includes(Role.MANAGE)
    // ) ||
    isOwner

  return (
    <>
      <Box minH='100vh' bg={bgColor} color={textColor} px={6}>
        <Container maxW='container.xl' py={8}>
          <VStack spacing={8} align='stretch'>
            <Flex justifyContent='space-between' alignItems='center'>
              <VStack align='flex-start' spacing={1}>
                <Heading size='2xl'>{projectData.name}</Heading>
                {projectData.isComplete && (
                  <Text>This project is complete. You can still make changes to it.</Text>
                )}
              </VStack>
              {!user && (
                <GoogleProvider returnUrl={window.location.href} shouldSave={false}></GoogleProvider>
              )}
            </Flex>

            <HStack display='flex' justifyContent='space-between'>
              <Box>
                {(canCreateTrack || isOwner) && (
                  <Button colorScheme='orange' mr={2} onClick={onOpenCreateTrack}>
                    Create
                  </Button>
                )}
              </Box>
              <HStack spacing={4} justifyContent='flex-end'>
                {isOwner && (
                  <>
                    <ShareClient
                      projectName={projectData.name}
                      projectID={projectID || ''}
                      isOwner={projectData.userIDs.includes(user?.uid || '')}
                    />
                    <Button
                      onClick={onOpenCompleteProject}
                      colorScheme='green'
                      fontSize='lg'
                      variant='outline'
                      _hover={{ color: 'white', bg: 'green' }}                      
                      size='md'
                    >
                      {projectData.isComplete? "Mark as Active":"Complete Project"}
                    </Button>
                  </>
                )}
              </HStack>
            </HStack>

            {tracksData.length === 0 ? (
              <Center h="400px">
                <Text
                  textAlign='center'
                  fontSize='lg'
                  fontWeight='bold'
                  color='blue.400'
                  width='50%'
                  height='auto'
                  alignSelf='center'
                  justifySelf='center'
                >
                  {'Get Started by creating a new track or sharing the project with another user 🚀'}
                </Text>
              </Center>
            ) : (
              <VStack spacing={4} align='stretch'>
                {Array.isArray(tracksData) &&
                  tracksData.map((track: Track) => (
                    <Box
                      key={track.id}
                      bg={trackBgColor}
                      p={4}
                      borderRadius='md'
                      _hover={{ bg: trackHoverBgColor }}
                      onClick={() => navigate(`/projects/${projectID}/${track.id}`)}
                    >
                      <HStack justifyContent='space-between'>
                        <HStack>
                          <IconButton
                            icon={<FaPlay />}
                            aria-label={`Play ${track.name}`}
                            variant='ghost'
                            color='green.500'
                            borderRadius='full'
                          />
                          <VStack align='flex-start' spacing={0}>
                            <Text fontWeight='bold'>{track.name}</Text>
                            <Text fontSize='sm' color={secondaryTextColor}>
                              TRACK
                            </Text>
                          </VStack>
                        </HStack>
                        <HStack>
                          <Menu>
                          <Tooltip label={user ? '' : 'Sign in to access this'} isDisabled={user?true:false}>
                            <MenuButton
                              as={IconButton}
                              icon={<FaEllipsisV />}
                              variant='ghost'
                              aria-label='Track options'
                              borderRadius='full'
                              _hover={{ bg: 'orange.400' }}
                              _active={{ bg: trackHoverBgColor }}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              isDisabled={user?false:true}
                            />
                            </Tooltip>
                            <MenuList>
                              <MenuItem
                                isDisabled={!isOwner}
                                icon={<FaEdit />}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setTrack(track)
                                  onOpenTrackDetails()
                                }}
                              >
                                Edit Track
                              </MenuItem>
                              <MenuItem
                                isDisabled={!isOwner}
                                icon={<FaTrash />}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleDeleteTrack(track.id)
                                }}
                              >
                                Delete Track
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </HStack>
                      </HStack>
                    </Box>
                  ))}
              </VStack>
            )}
          </VStack>
        </Container>
      </Box>
      <CreateTrack
        isOpen={isCreateTrackOpen}
        onClose={onCloseCreateTrack}
        project={projectData}
      />
      <TrackDetails
        isOpen={track !== null && isTrackDetailsOpen}
        onClose={onCloseTrackDetails}
        track={track}
        fetchTracksData={fetchTracksData}
      />
      <CompleteProject
        isOpen={isCompleteProjectOpen}
        onClose={onCloseCompleteProject}
        project={projectData}
      />
    </>
  )
}

export default ProjectTracks