import React from 'react'
import {
  Box,
  Button,
  Text,
  useColorModeValue,
  Icon,
  useDisclosure,
  Flex,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import {  Version } from '../../_helpers/types'
import { FaPlus, FaStar, FaRegStar, FaEllipsisV } from 'react-icons/fa'
import CreateVersion from '../project/CreateVersion'
import MarkFinalVersion from '../project/MarkFinalVersion'
import { useProjectContext } from '../project/ProjectContext'
import { useUser } from '../common/UserContext'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'

interface VersionSelectorProps {
  onVersionSelect: (index: number) => void
  isOwner: boolean
  isDisabled: boolean
  onMenuOpen: (version: Version, buttonRect: DOMRect) => void
  canCreateVersion: boolean
}

const VersionSelector: React.FC<VersionSelectorProps> = ({
  onVersionSelect,
  isOwner,
  isDisabled,
  onMenuOpen,
  canCreateVersion
}) => {
  const borderColor = useColorModeValue('gray.300', 'gray.300')
  const textColor = useColorModeValue('black', 'black')
  const selectedBgColor = useColorModeValue(
    'rgba(242, 135, 5, 1)',
    'rgba(242, 135, 5, 1)'
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isMarkFinalVersionOpen,
    onOpen: onMarkFinalVersionOpen,
    onClose: onMarkFinalVersionClose
  } = useDisclosure()

  const [user] = useAuthState(auth)

  const {versions, currentVersion, currentProject} = useProjectContext();
  const {canUserEditSharedProject, openRelevantModal} = useUser()

  if (versions) {
    return (
      <>
        <Box py={2} px={4} borderTopWidth={(versions?.length && versions.length > 0)? 1 : 0} borderColor={borderColor}>
          <Flex overflowX='auto' width='100%'>
            {[...versions].map((version, index) => (
              <Button
                key={version.id}
                onClick={() => onVersionSelect(index)}
                bg={version.id === currentVersion?.id ? selectedBgColor : 'transparent'}
                color={textColor}
                _hover={{
                  bg:
                    version.id === currentVersion?.id
                      ? selectedBgColor
                      : 'rgba(251, 232, 214, 0.7)'
                }}
                size='sm'
                maxWidth='20%'
                minWidth='10%'
                borderRadius='md'
                variant='outline'
                fontWeight='normal'
                colorScheme='orange'
                px={1}
                flex={1}
                mr={1}
                isDisabled={isDisabled}
              >
                <Flex alignItems='center' justifyContent='space-between' width='100%'>
                  <Box flex={1}>
                    <Text fontSize='xs' noOfLines={1} textAlign='center'>
                      {version.name}
                    </Text>
                  </Box>
                  { (
                    <Tooltip
                      label={version.isFinal ? 'Final Version' : 'Mark Version as Final'}
                    >
                      <IconButton
                        icon={
                          <Icon
                            as={version.isFinal ? FaStar : FaRegStar}
                            color={version.isFinal ? 'gold' : 'gray.400'}
                            boxSize={4}
                          />
                        }
                        aria-label={
                          version.isFinal ? 'Final version' : 'Mark as final version'
                        }
                        size='sm'
                        variant='ghost'
                        minWidth='auto'
                        height='auto'
                        _hover={{ bg: 'transparent' }}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (canUserEditSharedProject(currentProject)){
                            onMarkFinalVersionOpen()
                          } else {
                            openRelevantModal()
                          }
                        }}
                      />
                    </Tooltip>
                  )}
                  <IconButton
                    icon={<Icon as={FaEllipsisV} boxSize={3} />}
                    variant='ghost'
                    size='sm'
                    p={0}
                    bg='transparent'
                    _hover={{ bg: 'transparent' }}
                    _active={{ bg: 'transparent' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      const buttonRect = e.currentTarget.getBoundingClientRect()
                      onMenuOpen(version, buttonRect)
                    }}
                    aria-label='Open version menu'
                  />
                </Flex>
              </Button>
            ))}
            {(canCreateVersion || isOwner) && (
              <Tooltip label={user ? '' : 'Sign in to access this'} isDisabled={!user}>
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    if (canUserEditSharedProject(currentProject)) {
                      onOpen()
                    } else {
                      openRelevantModal()
                    }
                  }}
                  bg='transparent'
                  color={textColor}
                  _hover={{ bg: 'rgba(251, 232, 214, 0.7)' }}
                  size='sm'
                  borderRadius='md'
                  fontWeight='normal'
                  variant='outline'
                  colorScheme='orange'
                  px={3}
                  py={1}
                  leftIcon={<Icon as={FaPlus} />}
                  flexShrink={0}
                >
                  <Text fontSize='xs'>Version</Text>
                </Button>
              </Tooltip>
            )}
          </Flex>
        </Box>
        {isOwner && (
          <CreateVersion isOpen={isOpen} onClose={onClose} />
        )}
        <MarkFinalVersion
          isOpen={isMarkFinalVersionOpen}
          onClose={onMarkFinalVersionClose}
        />
      </>
    )
  } else {
    return null
  }
}

export default VersionSelector
