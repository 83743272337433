import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { collection, getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
// import firebaseConfig from '@/firebaseconfig.json'

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string)
const app = initializeApp(firebaseConfig as FirebaseOptions)
const db = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage(app)

// const analytics = getAnalytics(app)

const projectsDB = collection(db, 'projects')
const versionsDB = collection(db, 'versions')
const threadsDB = collection(db, 'threads')
// const waitlistDB = collection(db, 'waitlist')
const segmentationDB = collection(db, 'segmentation')
const permissionsDB = collection(db, 'permissions')
const notificationsDB = collection(db, 'notifications')

export {
  app,
  db,
  auth,
  storage,
  projectsDB,
  versionsDB,
  threadsDB,
  // waitlistDB,
  segmentationDB,
  permissionsDB,
  notificationsDB
}
