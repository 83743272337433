import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  VStack,
  Text,
  HStack
} from '@chakra-ui/react'

interface ActionConfirmationProps {
  prompt: string
  successMessage: string
  errorMessage: string
  action: () => Promise<boolean>
  isOpen: boolean
  onClose: () => void
}

const ActionConfirmation = ({
  prompt,
  successMessage,
  errorMessage,
  action,
  isOpen,
  onClose,
}: ActionConfirmationProps) => {

  const [executingAction, setExecutingAction] = useState(false)


  const toast = useToast()

  const handleAction = async () => {
    setExecutingAction(true)
    const actionResult = await action()
    setExecutingAction(false)
    onClose()

    if (actionResult) {
      toast({
        title: 'Success',
        description: successMessage,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderColor='orange.200' borderWidth={1}>
        <ModalHeader color='orange.800'>Confirmation</ModalHeader>
        <ModalCloseButton color='orange.800' />
        <ModalBody>
          <VStack spacing={4} align='stretch'>
            <Text color='orange.800'>{prompt}</Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
            <HStack spacing={4} align='stretch' justify='center' w='100%'>
                <Button
                    bg='white.400'
                    color='orange.400'
                    border={`1px solid`}
                    borderColor={`orange.400`}
                    _hover={{ bg: 'orange.500', color: 'white' }}
                    onClick={handleAction}
                    isLoading={executingAction}
                    isDisabled={executingAction}
                    size='md'>
                    Yes
                </Button>
                <Button
                    bg='orange.400'
                    color='white'
                    _hover={{ bg: 'orange.500' }}
                    onClick={onClose}
                    isDisabled={executingAction}
                    size='md'>
                    No
                </Button>
            </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ActionConfirmation
