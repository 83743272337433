import React, { useEffect } from 'react'
import {
  Box,
  VStack,
  HStack,
  Heading,
  Text,
  Button,
  IconButton,
  useColorModeValue,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useToast,
  Center,
  Tooltip,
  Spacer
} from '@chakra-ui/react'
import { FaPlay, FaEllipsisV, FaTrash, FaEdit } from 'react-icons/fa'
import { Project, Track } from '../../_helpers/types'
import LoadingScreen from '../common/LoadingScreen'
import { useNavigate } from 'react-router-dom'
import CreateTrack from './CreateTrack'
import TrackDetails from './TrackDetails'
import ShareClient from '../common/ShareClient'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
// import { getPermissions, Role } from '../../api/permission/permissions'
import CompleteProject from './CompleteProject'
import GoogleProvider from '../auth/GoogleProvider'
import MobileAccessErrorScreen from '../common/MobileAccessErrorScreen'
import { useProjectContext } from './ProjectContext'
import ErrorScreen from '../common/ErrorScreen'
import { SharedBadge, UpgradeBadge} from '../common/ProjectBadge'
import { isProjectShared } from './ProjectUtils'
import { useUser } from '../common/UserContext'


const ProjectTracks: React.FC<{ setShowSidebar: (show: boolean) => void }> = ({
  setShowSidebar
}) => {
  const [user] = useAuthState(auth)
  // const [permissions, setPermissions] = useState<Permission[]>([])
  const {tracks, loadingData, loadingError, setCurrentTrackId, currentProject, deleteTrack, currentTrack} = useProjectContext();
  const { canUserEditSharedProject, openRelevantModal } = useUser()

  const navigate = useNavigate()
  const toast = useToast()
  const {
    isOpen: isTrackDetailsOpen,
    onOpen: onOpenTrackDetails,
    onClose: onCloseTrackDetails
  } = useDisclosure()

  const {
    isOpen: isCompleteProjectOpen,
    onOpen: onOpenCompleteProject,
    onClose: onCloseCompleteProject
  } = useDisclosure()

  const {
    isOpen: isCreateTrackOpen,
    onOpen: onOpenCreateTrack,
    onClose: onCloseCreateTrack
  } = useDisclosure()

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  useEffect(() => {
    if (isMobile()) {
      setShowSidebar(false)
    } else {
      setShowSidebar(true)
    }
  }, [setShowSidebar])

  // const fetchPermissions = useCallback(async () => {
  //   if (!projectID || !user?.uid) return
  //   const permissions = await getPermissions(projectID, user?.uid)
  //   setPermissions(permissions || [])
  // }, [projectID, user?.uid])

  // useEffect(() => {
  //   fetchPermissions()
  // }, [fetchPermissions])

  const bgColor = useColorModeValue('beige', 'gray.900')
  const textColor = useColorModeValue('black', 'white')
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')
  const trackBgColor = useColorModeValue('orange.100', 'gray.800')
  const trackHoverBgColor = useColorModeValue('orange.200', 'gray.700')

  // const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0]
  //   if (file) {
  //     const formData = new FormData()
  //     formData.append('file', file)

  //     try {
  //       await axios.post(`${BASE_URL}/tracks`, formData, {
  //         headers: {
  //           'Content-Type': 'multipart/form-data'
  //         }
  //       })

  //       fetchTracksData()
  //     } catch (error) {
  //       console.error('Error uploading file:', error)
  //     }
  //   }
  // }

  const handleDeleteTrack = async (trackId: string) => {
    if (window.confirm(`Are you sure you want to delete the track?`)) {
      const successToastId = toast({
        title: 'Track Deleted',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
      const deleteTrackStatus = await deleteTrack(trackId)
      if (!deleteTrackStatus) {
        if (successToastId) {
          toast.close(successToastId)
        }
        toast({
          title: 'Error',
          description: 'There was an error deleting the track. Please reload your page and try again.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  if (isMobile()) {
    return <MobileAccessErrorScreen home='/' />
  }
  
  if (loadingData) return <LoadingScreen />

  if (loadingError || !currentProject || !currentProject.userIDs) {
    return <ErrorScreen error={new Error("Project not found")} hideHeader/>
  }

  const handleCreateTrackClick = () => {
    if (canUserEditSharedProject(currentProject)) {
      onOpenCreateTrack();
    } else {
      openRelevantModal();
    }
  };

  const isOwner =
    // permissions.some((permission) => permission.roles.includes(Role.MANAGE)) ||
    user && (currentProject?.userIDs.includes(user?.uid || '') ||
    currentProject?.userID === user?.uid)

  const canCreateTrack = 
    // permissions.some(
    //   (permission) =>
    //     permission.roles.includes(Role.UPLOAD) || permission.roles.includes(Role.MANAGE)
    // ) ||
    isOwner

  return (
    <>
      <Box minH='100vh' bg={bgColor} color={textColor} px={6}>
        <Container maxW='container.xl' py={8}>
          <VStack spacing={8} align='stretch'>
            <Flex justifyContent='space-between' alignItems='center'>
              <VStack align='flex-start' spacing={1}>
                <HStack>
                  <Heading size='2xl'>{currentProject.name}</Heading>
                  <Spacer/>
                  {isProjectShared(currentProject) && <SharedBadge fontSize="xl" />}
                </HStack>
                {currentProject.isComplete && (
                  <Text>This project is complete. You can still make changes to it.</Text>
                )}
              </VStack>
              <Spacer/>
              {!user && (
                <GoogleProvider returnUrl={window.location.href} shouldSave={false}></GoogleProvider>
              )}
              {user && !canUserEditSharedProject(currentProject) && <UpgradeBadge fontSize="sm" text={"UPGRADE to edit"}/>}
            </Flex>

            <HStack display='flex' justifyContent='space-between'>
              <Box>
                {(canCreateTrack || isOwner) && (
                  <Button colorScheme='orange' mr={2} onClick={handleCreateTrackClick}>
                    Create Track
                  </Button>
                )}
              </Box>
              <HStack spacing={4} justifyContent='flex-end'>
                {isOwner && (
                  <>
                    <ShareClient/>
                    <Button
                      onClick={onOpenCompleteProject}
                      colorScheme='green'
                      fontSize='lg'
                      variant='outline'
                      _hover={{ color: 'white', bg: 'green' }}                      
                      size='md'
                    >
                      {currentProject?.isComplete? "Mark as Active":"Complete Project"}
                    </Button>
                  </>
                )}
              </HStack>
            </HStack>

            {tracks?.length === 0 ? (
              <Center h="400px">
                <Text
                  textAlign='center'
                  fontSize='lg'
                  fontWeight='bold'
                  color='blue.400'
                  width='50%'
                  height='auto'
                  alignSelf='center'
                  justifySelf='center'
                >
                  {'Get Started by creating a new track or sharing the project with another user 🚀'}
                </Text>
              </Center>
            ) : (
              <VStack spacing={4} align='stretch'>
                {Array.isArray(tracks) &&
                  tracks
                  .filter((item, index, self) => self.findIndex(i => i.id === item.id) === index)
                  .sort((a: Track, b: Track) => {
                    const dateA = new Date(a.createdAt)
                    const dateB = new Date(b.createdAt)
                    return dateA.getTime() - dateB.getTime()
                  })
                  .map((track: Track) => (
                    <Box
                      key={track.id}
                      bg={trackBgColor}
                      p={4}
                      borderRadius='md'
                      _hover={{ bg: trackHoverBgColor }}
                      onClick={() => navigate(`/projects/${currentProject?.id}/${track.id}`)}
                    >
                      <HStack justifyContent='space-between'>
                        <HStack>
                          <IconButton
                            icon={<FaPlay />}
                            aria-label={`Play ${track.name}`}
                            variant='ghost'
                            color='green.500'
                            borderRadius='full'
                          />
                          <VStack align='flex-start' spacing={0}>
                            <Text fontWeight='bold'>{track.name}</Text>
                            <Text fontSize='sm' color={secondaryTextColor}>
                              TRACK
                            </Text>
                          </VStack>
                        </HStack>
                        <HStack>
                          <Menu>
                          <Tooltip label={user ? '' : 'Sign in to access this'} isDisabled={user?true:false}>
                            <MenuButton
                              as={IconButton}
                              icon={<FaEllipsisV />}
                              variant='ghost'
                              aria-label='Track options'
                              borderRadius='full'
                              _hover={{ bg: 'orange.400' }}
                              _active={{ bg: trackHoverBgColor }}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              isDisabled={user?false:true}
                            />
                            </Tooltip>
                            <MenuList>
                              <MenuItem
                                isDisabled={!isOwner}
                                icon={<FaEdit />}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  if (canUserEditSharedProject(currentProject)) {
                                    setCurrentTrackId(track.id)
                                    onOpenTrackDetails()
                                  } else {
                                    openRelevantModal();
                                  }
                                }}
                              >
                                Edit Track
                              </MenuItem>
                              <MenuItem
                                isDisabled={!isOwner}
                                icon={<FaTrash />}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  if (canUserEditSharedProject(currentProject)) {
                                    handleDeleteTrack(track.id)
                                  } else {
                                    openRelevantModal();
                                  }
                                }}
                              >
                                Delete Track
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </HStack>
                      </HStack>
                    </Box>
                  ))}
              </VStack>
            )}
          </VStack>
        </Container>
      </Box>

      {currentProject && user &&
        <CreateTrack
        isOpen={isCreateTrackOpen}
        onClose={onCloseCreateTrack}
      />
      }
      <TrackDetails
        isOpen={currentTrack !== null && isTrackDetailsOpen}
        onClose={onCloseTrackDetails}
        track={currentTrack}
      />
      {currentProject &&
        <CompleteProject
          isOpen={isCompleteProjectOpen}
          onClose={onCloseCompleteProject}
          project={currentProject as Project}
        />
      }
    </>
  )
}

export default ProjectTracks