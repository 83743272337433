import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text
} from '@chakra-ui/react'
import GoogleProvider from './GoogleProvider' // Ensure this is the correct import path

interface SignInModalProps {
    isOpen: boolean
    onClose: () => void
}

const SignInModal: React.FC<SignInModalProps> = ({isOpen, onClose}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
        <ModalHeader>Sign in to access this feature</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            <Text fontSize='md' mb={4}>
                It takes less than a minute to get started!
            </Text> 
        <VStack spacing={4} align="stretch">
            <GoogleProvider shouldSave={false} />
        </VStack>
        </ModalBody>
        <ModalFooter>
        <Button variant="ghost" onClick={onClose}>
            Close
        </Button>
        </ModalFooter>
    </ModalContent>
    </Modal>
  )
}

export default SignInModal
