import React, { useState } from 'react'
import {
  useToast,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  InputGroup,
  InputRightElement,
  ModalCloseButton,
  VStack,
} from '@chakra-ui/react'
import { FaUpload, FaPlus, FaTrash } from 'react-icons/fa'
import { useProjectContext } from './ProjectContext'
import { useNavigate } from 'react-router-dom'

interface CreateProjectProps {
  isOpen: boolean
  onClose: () => void
}

const CreateProject = (props: CreateProjectProps) => {
  const [trackNames, setTrackNames] = useState<string[]>([])
  const [projectName, setProjectName] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const [creatingProject, setCreatingProject] = useState(false)

  const toast = useToast()
  const navigate = useNavigate()

  const {createProject} = useProjectContext();

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0] || null

    if (file) {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles]
        newFiles[index] = file
        return newFiles
      })
    }
  }

  const create = async () => {
    setCreatingProject(true)
    props.onClose()
    const createResult = await createProject(files, projectName, trackNames)
    setCreatingProject(false)

    if (createResult) {
      navigate(`/projects/${createResult}`)
      toast({
        title: 'Project Created',
        description: 'Your project has been created successfully!',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Error',
        description: `There was an error creating the project. Please reload your page and try again.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const addTrack = () => {
    setTrackNames([...trackNames, ''])
    setFiles([...files, new File([''], '')])
  }

  const removeTrack = (index: number) => {
    setTrackNames(trackNames.filter((_, i) => i !== index))
    setFiles(files.filter((_, i) => i !== index))
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={8}>
          <VStack spacing={6}>
            <Heading as='h2' size='lg'>
              Create New Project
            </Heading>
            <FormControl>
              <FormLabel>Project Name</FormLabel>
              <Input
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                placeholder='My Awesome Project'
              />
            </FormControl>
            {trackNames.map((trackName, index) => (
              <Box key={index} w='100%'>
              <FormControl>
                <FormLabel>Track {index + 1}</FormLabel>
                <InputGroup>
                <Input
                  value={trackName}
                  onChange={(e) => {
                  const newTrackNames = [...trackNames]
                  newTrackNames[index] = e.target.value
                  setTrackNames(newTrackNames)
                  }}
                  placeholder={`Track ${index + 1} name`}
                />
                <InputRightElement>
                  <Button
                  size='sm'
                  onClick={() => removeTrack(index)}
                  variant='ghost'
                  colorScheme='red'
                  >
                  <Icon as={FaTrash} />
                  </Button>
                </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl mt={2}>
                <Input
                type='file'
                accept='audio/*'
                onChange={(e) => handleFileUpload(e, index)}
                hidden
                id={`file-input-${index}`}
                />
                <Button
                as='label'
                htmlFor={`file-input-${index}`}
                leftIcon={<FaUpload />}
                variant='outline'
                colorScheme='orange'
                w='100%'
                isTruncated
                >
                {files[index]?.name || 'Upload Audio'}
                </Button>
              </FormControl>
              </Box>
            ))}
            <Button
              leftIcon={<FaPlus />}
              onClick={addTrack}
              variant='ghost'
              colorScheme='green'
            >
              Add Track
            </Button>
            <Button
              colorScheme='blue'
              isLoading={creatingProject}
              onClick={create}
              isDisabled={
                !projectName ||
                files.some((file) => !file.name)
              }
              w='100%'
            >
              Create Project
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateProject