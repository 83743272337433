import React, { useState, useEffect } from 'react'
import {
  Button,
  HStack,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Input,
  useToast,
  Heading,
  Box,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Avatar,
  Spacer
} from '@chakra-ui/react'
import { LinkIcon } from '@chakra-ui/icons'
import emailjs from '@emailjs/browser'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'

import { auth } from '../../firebase'
import { getUsersForProject } from '../../api/permission/permissions'
import { useUser } from './UserContext'
import { useProjectContext } from '../project/ProjectContext'


const ShareClient: React.FC = () => {
  const toast = useToast()
  const [user] = useAuthState(auth)
  const [emailInput, setEmailInput] = useState('')

  const [collaborators, setCollaborators] = useState<string[]>([]) // List of emails

  const {openUpgradeModal, canUserShareProject} = useUser();
  const {currentProject} = useProjectContext();

  useEffect(() => {
    const fetchCollaborators = async () => {
      const usersList = await getUsersForProject(currentProject?.id || '')
      setCollaborators(usersList)
    }

    fetchCollaborators()
  }, [currentProject?.id])

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
    // handleEmailInput(e.target.value);
  };

  const handleKeyPressForEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && emailInput) {
      e.preventDefault();
      handleSendInvite()
    }
  };

  const canShare = () => {
    if (currentProject) {
      // See if the user restrictions prevent project from being shared
      if (!canUserShareProject(currentProject)) {
        openUpgradeModal()
        
        // Open UpgradeModal
        toast({
          title: 'Could not share',
          description: 'You have the maximum number of shared projects',
          status: 'warning',
          duration: 3000,
          isClosable: true
        })
        return false
      }
      return true
    }
    return false
  }

  const handleCopyLink = async () => {

    if (!(canShare())) return

    const linkToCopy = window.location.href
    navigator.clipboard.writeText(linkToCopy)
    toast({
      title: 'Share Link Copied',
      description: 'The share link has been copied to your clipboard!',
      status: 'success',
      duration: 3000,
      isClosable: true
    })

    // Track Share Link Copied event
    amplitude.track('Share Link Copied', {
      projectID: currentProject?.id,
      userID: user?.uid
    })
  }

  const handleSendInvite = async () => {
    if (!(canShare())) return

    const currentEmail = emailInput.trim();

    if (currentEmail.includes('@') && !collaborators.includes(currentEmail)) {
      sendEmail([currentEmail])

    } else if (!currentEmail.includes('@')) {
      toast({
        title: 'Invalid email',
        description: 'Please enter a valid email address.',
        status: 'warning',
        duration: 3000,
        isClosable: true
      })
    } else {
      toast({
        title: 'Warning',
        description: 'This person is already a collaborator.',
        status: 'warning',
        duration: 5000,
        isClosable: true
      })
    }
    setEmailInput(''); // Clear input after pressing Enter
  };
  

  const sendEmail = (inviteesList: string[]) => {
    try {
      // TODO: Convert to env variables for frontend
      const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID as string
      const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string
      const userPublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string      

      inviteesList.forEach((invitee) => {

        // Update permissions in the database for each invitee
        // updatePermissions(currentProject?.id || '', invitee)

        //  TODO: Need some intermediary state for PENDING users that only makes 
        //  it shared once they accept the invite

        // TODO: retry sending email if it fails
        emailjs.send(
          serviceId,
          templateId,
          {
            projectName: currentProject?.name,
            freelancerName: user?.displayName,
            clientLink: `${window.location.href}`,
            clientEmail: invitee
          },
          {
            publicKey: userPublicKey
          }
        )

        amplitude.track('Project Shared', {
          projectID: currentProject?.id,
          userID: user?.uid
        })
      })

      toast({
        title: 'Email Sent Successfully!',
        description: 'Your project has been shared! User will be added once they join the project',
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error sending your email. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const handleClose = () => {
    setEmailInput('')
  }

  return (
    <HStack>
      <Popover onClose={handleClose}>
        <PopoverTrigger>
          <Button
            colorScheme='orange'
            fontSize='lg'
            variant='outline'
            _hover={{ color: 'white', bg: 'orange.500' }}
            _active={{ color: 'white', bg: 'orange.500' }}
            size='md'
          >
            Share
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width='400px'>
            <PopoverCloseButton />
            <PopoverBody>
              <Tabs>
                <TabList>
                  <Tab>Invite</Tab>
                  <Tab>Share Link</Tab>
                  <Tab>Collaborators</Tab>
                </TabList>
                <TabPanels>
                <TabPanel>
                    <Heading as='h3' mt={1} mb={3} size='sm' textAlign='center'>
                      Invite users to collaborate!
                    </Heading>
                    <Text marginBottom='5px' color='gray.600'>
                      Collaborator Email
                    </Text>
                    <Input
                      value={emailInput}
                      onChange={handleEmailChange}
                      onKeyDown={handleKeyPressForEmail}
                      placeholder='Enter email'
                      mb={2}
                    />
                    <Button
                      onClick={handleSendInvite}
                      colorScheme='orange'
                      fontSize='md'
                      variant='ghost'
                      _hover={{ color: 'white', bg: 'orange.400' }}
                      _active={{ color: 'white', bg: 'orange.400' }}
                      size='sm'
                      isDisabled={!emailInput}
                      my={2}
                    >
                      Send Invite
                    </Button>
                  </TabPanel>
                  <TabPanel>
                    <Heading as='h3' mt={1} mb={3} size='sm' textAlign='center'>
                      Share Link (View Only)
                    </Heading>
                    <Tooltip label='Copy Share Link' aria-label='Copy Share Link'>
                      <Button
                        colorScheme='orange'
                        variant='outline'
                        _hover={{ color: 'white', bg: 'orange.400' }}
                        size='sm'
                        onClick={handleCopyLink}
                        leftIcon={<LinkIcon />}
                        width='100%'
                      >
                        Copy Link
                      </Button>
                    </Tooltip>
                  </TabPanel>
                  <TabPanel>
                    {collaborators.map((collaborator, index) => (
                      <HStack key={index} spacing={2} p={1}>
                        <Avatar name={collaborator} size='sm' />
                        <Box>
                          <Text fontWeight='bold' fontSize='sm'>
                            {collaborator}
                          </Text>
                        </Box>
                        <Spacer/>
                        {currentProject && collaborator === currentProject.createdBy && (
                          <Box>
                          <Text fontWeight='light' fontSize='sm'>
                            OWNER
                          </Text>
                        </Box>
                        )}
                      </HStack>
                    ))}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </HStack> 
  )
}

export default ShareClient
