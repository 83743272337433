import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Button,
  Box,
  Flex,
  Icon,
} from '@chakra-ui/react'
import { FaTag } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useUser } from './UserContext'

interface UpgradeModalProps {
  header?: string
  isOpen: boolean
  onClose: () => void
  onUpgrade?: () => void // Optional callback for handling upgrades
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({ header = 'Upgrade to Pro!', isOpen, onClose, onUpgrade }) => {
  const navigate = useNavigate()
  const { soloflowUser } = useUser()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><strong>{header}</strong></ModalHeader>
        <ModalCloseButton />
        <ModalBody> 
          <Text fontSize='md' mb={4}>
            Edit, comment, and collaborate on unlimited shared projects by upgrading to Pro.
          </Text>
          <Text fontSize='sm' color='gray.500'>
            It takes less than a minute!
          </Text>
          <Box 
            bg="orange.50" 
            p={3} 
            borderRadius="md" 
            mt={4}
          >
            <Flex align="center" mb={2}>
              <Icon as={FaTag} color="orange.500" mr={2} />
              <Text fontWeight="bold" color="orange.600">
                Limited Time Offer!
              </Text>
            </Flex>
            <Text fontSize="sm">
              Get 30% off - Now only <strong>$7.99/month</strong>
              <Text as="span" textDecoration="line-through" color="gray.500" ml={2}>
                $10.99
              </Text>
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme='orange'
            mr={3}
            onClick={() => {
              if (onUpgrade) {
                onUpgrade() // Trigger optional upgrade handler
              } else {
                navigate('/pricing?checkout=true')
                onClose()
              }
            }}
          >
            {soloflowUser?.stripe_customer_id ? 'Upgrade Now' : 'Start Free Trial'}
          </Button>
          <Button variant='ghost' onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpgradeModal
