import React, { useState, useEffect } from 'react'
import {
  useToast,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  VStack
} from '@chakra-ui/react'
import { FaUpload } from 'react-icons/fa'
import { auth } from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

import { Version } from '../../_helpers/types'
import { useProjectContext } from './ProjectContext'

interface CreateVersionProps {
  isOpen: boolean
  onClose: () => void
}

const CreateVersion = (props: CreateVersionProps) => {
  const [versionName, setVersionName] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [isNameCollision, setIsNameCollision] = useState(false)

  const [creatingVersion, setCreatingVersion] = useState(false)

  const { versions, currentTrack, createVersion } = useProjectContext();

  const toast = useToast()
  const [user] = useAuthState(auth)

  useEffect(() => {
    if (versionName) {
      checkVersionNameCollision()
    }
  }, [versionName])

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files?.[0] || null
    if (newFile) {
      setFile(newFile)
    }
  }

  const checkVersionNameCollision = async () => {
    if (versions) {
      const currVersionNames = versions.map((version: Version) => version.name)
      if (
        currVersionNames.some(
          (name: string) =>
            name.toLowerCase().replace(/\s/g, '') ===
            versionName.toLowerCase().replace(/\s/g, '')
        )
      ) {
        setIsNameCollision(true)
        toast({
          title: 'Warning',
          description: 'You cannot have the same name as another version in the track.',
          status: 'warning',
          duration: 5000,
          isClosable: true
        })
      } else {
        setIsNameCollision(false)
      }
    }
  }

  const createVersionInternal = async () => {

    if (file && user && currentTrack?.id) {
      setCreatingVersion(true)
      const createVersionResult = await createVersion(file, versionName, currentTrack.id)
      setCreatingVersion(false)
      props.onClose()

      if (createVersionResult) {
        toast({
          title: 'Version Created',
          description: 'Your version has been created successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } else {
        toast({
          title: 'Error',
          description: `There was an error creating the version. Please reload your page and try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }

  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={8}>
          <VStack spacing={6}>
            <Heading as='h2' size='lg'>
              Create New Version
            </Heading>
            <Box w='100%'>
              <FormControl>
                <FormLabel>Version Name</FormLabel>
                <Input
                  value={versionName}
                  onChange={(e) => setVersionName(e.target.value)}
                  placeholder='Enter version name'
                />
              </FormControl>
              <FormControl mt={4}>
                <Input
                  type='file'
                  accept='audio/*'
                  onChange={handleFileUpload}
                  hidden
                  id='file-input'
                />
                <Button
                  as='label'
                  htmlFor='file-input'
                  leftIcon={<FaUpload />}
                  variant='outline'
                  colorScheme='orange'
                  w='100%'
                  isTruncated
                >
                  {file ? file.name : 'Upload Audio'}
                </Button>
              </FormControl>
            </Box>
            <Button
              colorScheme='blue'
              isLoading={creatingVersion}
              onClick={createVersionInternal}
              isDisabled={!versionName || isNameCollision || !file}
              w='100%'
            >
              Create Version
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateVersion
